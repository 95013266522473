import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import AppRoutes from './routes';
import customTheme from './theme';
import store, { persistor, rrfProps } from './redux/store';
import AppProviders from './providers/appProviders';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppProviders>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ReactReduxFirebaseProvider {...rrfProps}>
            <ThemeProvider theme={customTheme}>
              <AppRoutes />
            </ThemeProvider>
          </ReactReduxFirebaseProvider>
        </AppProviders>
      </PersistGate>
    </Provider>
  );
}

export default App;
