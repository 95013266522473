/* eslint-disable max-len */
// eslint-disable-next-line max-classes-per-file
import { FieldValue, serverTimestamp, Timestamp } from 'firebase/firestore';
import { PlainObject } from '../../types';
import { toJsonObject, toPlainObject } from './utils';

export class Base {
  toPlainObject():PlainObject<Base> {
    return toPlainObject(this);
  }

  toJSON():Object {
    return toJsonObject(this);
  }
}

export class FirebaseBase extends Base {
  id:string;

  created_at: Timestamp | FieldValue;

  updated_at:FieldValue | Timestamp;

  enable:boolean;

  constructor({
    id = '',
    created_at = serverTimestamp(),
    updated_at = serverTimestamp(),
    enable = true,
  }) {
    super();
    this.id = id;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.enable = enable;
  }
}

export default FirebaseBase;
