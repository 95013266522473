import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useMemo } from 'react';
import { COLLECTIONS } from '../constants';
import { useAppSelector } from './redux';

interface RestSubNameProps {
  rest_name: string
  sub_name: string
}

export const useSubsidiaryByRestSubName = ({ rest_name, sub_name }: RestSubNameProps) => {
  const restaurant_name = rest_name.toLowerCase();
  const subsidiary_name = sub_name.toLowerCase();

  if (restaurant_name && subsidiary_name) {
    useFirestoreConnect([
      {
        collection: COLLECTIONS.SUBSIDIARY,
        where: [
          ['normalized_name', '==', subsidiary_name],
          ['normalized_restaurant_name', '==', restaurant_name],
        ],
        storeAs: 'subsidiary_by_name',
      },
    ]);
    const subsidiary = useSelector((state:any) => state.firestore.ordered.subsidiary_by_name);
    if (subsidiary) {
      return subsidiary[0];
    }
    return null;
  }
  return null;
};

export const useSubsidiaryFromRedux = (subsidiary_id? : string) => {
  const homeData = useAppSelector((store) => store.home.data);
  return useMemo(() => {
    if (subsidiary_id && homeData?.subsidiaries) {
      return homeData?.subsidiaries?.find((sub) => sub.id === subsidiary_id);
    }
    return null;
  }, [subsidiary_id, homeData?.subsidiaries]);
};

export default {
  useSubsidiaryByRestSubName,
  useSubsidiaryFromRedux,
};
