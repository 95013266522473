import React from 'react';
import {
  Stack, Typography, Grid,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export interface AlertProps {
  title: string;
  description:string;
  color?: string;
  textColor?:string;
  actionText:string;
  onClick:()=>void
}

export function AlertMessage({
  title, color = '#F3E55A ', textColor = 'black',
  onClick, description, actionText,
}: AlertProps) {
  return (
    <Stack
      sx={{
        width: '100%',
        backgroundColor: color,
        padding: 1,
        borderRadius: 2,
      }}
    >
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <InfoOutlinedIcon sx={{ color: textColor }} />
        </Grid>
        <Grid item>
          <Typography color={textColor} fontWeight="bold" variant="h6">
            {title}
          </Typography>
          <Typography color={textColor}>
            {description}
          </Typography>
          <Typography
            fontWeight="bold"
            onClick={onClick}
            sx={{
              cursor: 'pointer',
              color: textColor,
              textDecoration: 'underline',
            }}
          >
            {actionText}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AlertMessage;
