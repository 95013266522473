import React, {
  CSSProperties, MutableRefObject, useLayoutEffect, useMemo, useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Nullable } from '../../types';

type PinFloatFixedProps = {containerRef:MutableRefObject<HTMLDivElement | undefined>}

export function PinFloatFixed({ containerRef }:PinFloatFixedProps) {
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const [dimensions, setDimensions] = useState<Nullable<{height:number, width:number}>>(null);
  const style:Partial<CSSProperties> = useMemo(() => {
    if (dimensions) {
      return {
        position: 'absolute',
        top: dimensions.height * 0.5,
        left: dimensions.width * 0.5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    }
    return {};
  }, [dimensions]);
  useLayoutEffect(() => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, [containerRef, windowDimensions]);

  return (
    <div style={style}>
      <LocationOnIcon style={{
        // @ts-ignore
        fontSize: 70,
        top: -64,
        color: theme.palette.primary.main,
        position: 'absolute',
        filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
      }}
      />
    </div>
  );
}
