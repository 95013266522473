import React, { useState, useRef } from 'react';
import { isPointInPolygon } from 'geolib';
import {
  GoogleMap, LoadScript,
} from '@react-google-maps/api';
import { PinFloatFixed } from './pinFloatFixed';
import { City } from '../../dto';

type MapAddressProps = {
  address:string
  onCenterChange:(newCenter:google.maps.LatLngLiteral)=>void
  onOutOfCity:()=>void
  city: City | undefined,
}

export function MapAddress({
  address, city, onCenterChange = () => {}, onOutOfCity = () => {},
}:MapAddressProps) {
  const [approximatedCenter, setApproximatedCenter] = useState<
    google.maps.LatLngLiteral>();
  const containerRef = useRef<HTMLDivElement>();
  const mapInstance = useRef<google.maps.Map>();
  const validateLocation = (findLocation) => {
    if (city && findLocation) {
      const polygon = city.coverage_polygon
        .map((item) => ({ latitude: item.latitude, longitude: item.longitude }));
      return isPointInPolygon(findLocation, polygon);
    }
    return false;
  };
  const onLoad = React.useCallback((map) => {
    mapInstance.current = map;
    const request = {
      query: address,
      fields: ['name', 'geometry'],
    };
    const service = new google.maps.places.PlacesService(map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (results && results[0]) {
          const newCenter = results[0];
          const location = newCenter.geometry?.location;
          if (location) {
            const locationLiteral = { lat: location.lat(), lng: location.lng() };
            const validatationInCity = validateLocation({
              latitude: location.lat(),
              longitude: location.lng(),
            });
            if (validatationInCity) {
              setApproximatedCenter(locationLiteral);
              onCenterChange(locationLiteral);
            } else {
              onOutOfCity();
            }
          }
        }
      } else {
        const cityLocation = city?.location;
        if (cityLocation) {
          const locationLiteral = { lat: cityLocation.latitude, lng: cityLocation.longitude };
          setApproximatedCenter(locationLiteral);
          onCenterChange(locationLiteral);
        } else {
          onOutOfCity();
        }
      }
    });
  }, []);

  const onDrag = () => {
    const mapCenter = mapInstance.current?.getCenter();
    if (mapCenter) {
      onCenterChange({ lat: mapCenter.lat(), lng: mapCenter.lng() });
    }
  };
  return (
    <div
      style={{ width: '100%', height: '50vh' }}
      // @ts-ignore
      ref={containerRef}
    >
      <LoadScript
        googleMapsApiKey="AIzaSyBex46zHORVn63VFPsYrn4elJPhL3sxCWA"
        libraries={['places']}
      >
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={approximatedCenter}
          zoom={15}
          onLoad={onLoad}
          onDrag={onDrag}
        >
          <PinFloatFixed containerRef={containerRef} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default MapAddress;
