// eslint-disable-next-line
import React from 'react';
import App from './App';
import './index.css';
import 'font-awesome/css/font-awesome.css';

function MemoryGame() {
  return <App />;
}
export default MemoryGame;
