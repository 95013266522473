import { createSlice } from '@reduxjs/toolkit';

type State = {
  searchNavbar:string;
}

const initialState: State = {
  searchNavbar: '',
};

export const navBarSlice = createSlice({
  name: 'navBar',
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchNavbar = action.payload;
    },
  },
});

export const { setSearchTerm } = navBarSlice.actions;
export default navBarSlice.reducer;
