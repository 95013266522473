const map = require('./map.png');
const logo = require('./logo.png');
const logo1 = require('./logo1.png');
const ghost = require('./ghost.png');

const Icons = {
  map,
  logo,
  logo1,
  ghost,
};
export default Icons;
