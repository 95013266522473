// eslint-disable-next-line max-classes-per-file
import FirebaseBase from '../firebaseBase';
import Photo from '../photo';

export class NameTranslated {
  en: string;

  constructor({ en = '' }) {
    this.en = en;
  }
}

export class Category extends FirebaseBase {
  img: Photo;

  name: string;

  nameTranslated: NameTranslated;

  value: string;

  constructor({
    img = new Photo({}),
    name = '',
    nameTranslated = new NameTranslated({}),
    value = '',
    ...data
  }:Partial<Category>) {
    super(data);
    this.img = img ? new Photo(img) : img;
    this.name = name;
    this.nameTranslated = nameTranslated ? new NameTranslated(nameTranslated) : nameTranslated;
    this.value = value;
  }
}

export default Category;
