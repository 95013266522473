import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { FirestoreReducer, firestoreReducer as fr } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import userReducer from './user';
import categoryReducer from './category';
import loaderSlice from './loader';
import citiesReducer from './cities';
import navBarReducer from './navBar';
import addressReducer from './address';
import homeReducer from './home';

const firestoreReducer: Reducer<FirestoreReducer.Reducer<Record<any,
 any>>> = fr;

const rootReducers = combineReducers({
  address: addressReducer,
  users: userReducer,
  categories: categoryReducer,
  loaders: loaderSlice,
  cities: citiesReducer,
  navBar: navBarReducer,
  firebase: firebaseReducer,
  home: homeReducer,
  firestore: firestoreReducer,
});

export type RootReducersType = typeof rootReducers;
export default rootReducers;
