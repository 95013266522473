const l1 = require('../../assets/img/brands/l1.png');
const l2 = require('../../assets/img/brands/l2.png');
const l3 = require('../../assets/img/brands/l3.png');
const l4 = require('../../assets/img/brands/l4.png');
const l5 = require('../../assets/img/brands/l5.png');
const l6 = require('../../assets/img/brands/l6.png');
const l7 = require('../../assets/img/brands/l7.png');
const l8 = require('../../assets/img/brands/l8.png');
const l9 = require('../../assets/img/brands/l9.png');
const l10 = require('../../assets/img/brands/l10.png');
const l11 = require('../../assets/img/brands/l11.png');
const l12 = require('../../assets/img/brands/l12.png');
const l13 = require('../../assets/img/brands/l13.png');
const l14 = require('../../assets/img/brands/l14.png');
const l15 = require('../../assets/img/brands/l15.png');
const l16 = require('../../assets/img/brands/l16.png');
const l17 = require('../../assets/img/brands/l17.png');
const l18 = require('../../assets/img/brands/l18.png');
const l19 = require('../../assets/img/brands/l19.png');
const l20 = require('../../assets/img/brands/l20.png');
const l21 = require('../../assets/img/brands/l21.png');
const l22 = require('../../assets/img/brands/l22.png');
const l23 = require('../../assets/img/brands/l23.png');
const l24 = require('../../assets/img/brands/l24.png');
const l25 = require('../../assets/img/brands/l25.png');
const l26 = require('../../assets/img/brands/l26.png');
const l27 = require('../../assets/img/brands/l27.png');
const l28 = require('../../assets/img/brands/l28.png');
const l29 = require('../../assets/img/brands/l29.png');
const l30 = require('../../assets/img/brands/l30.png');
const l31 = require('../../assets/img/brands/l31.png');
const l32 = require('../../assets/img/brands/l32.png');
const l33 = require('../../assets/img/brands/l33.png');
const l34 = require('../../assets/img/brands/l34.png');
const l35 = require('../../assets/img/brands/l35.png');
const l36 = require('../../assets/img/brands/l36.png');
const l37 = require('../../assets/img/brands/l37.png');
const l38 = require('../../assets/img/brands/l38.png');

const logos = [
  {
    alt: 'l1',
    index: 1,
    src: l1,
  },
  {
    alt: 'l2',
    index: 2,
    src: l2,
  },
  {
    alt: 'l3',
    index: 3,
    src: l3,
  },
  {
    alt: 'l4',
    index: 4,
    src: l4,
  },
  {
    alt: 'l5',
    index: 5,
    src: l5,
  },
  {
    alt: 'l6',
    index: 6,
    src: l6,
  },
  {
    alt: 'l7',
    index: 7,
    src: l7,
  },
  {
    alt: 'l8',
    index: 8,
    src: l8,
  },
  {
    alt: 'l9',
    index: 9,
    src: l9,
  },
  {
    alt: 'l10',
    index: 10,
    src: l10,
  },
  {
    alt: 'l11',
    index: 11,
    src: l11,
  },
  {
    alt: 'l12',
    index: 12,
    src: l12,
  },
  {
    alt: 'l13',
    index: 13,
    src: l13,
  },
  {
    alt: 'l14',
    index: 14,
    src: l14,
  },
  {
    alt: 'l15',
    index: 15,
    src: l15,
  },
  {
    alt: 'l16',
    index: 16,
    src: l16,
  },
  {
    alt: 'l17',
    index: 17,
    src: l17,
  },
  {
    alt: 'l18',
    index: 18,
    src: l18,
  },
  {
    alt: 'l19',
    index: 19,
    src: l19,
  },
  {
    alt: 'l20',
    index: 20,
    src: l20,
  },
  {
    alt: 'l21',
    index: 21,
    src: l21,
  },
  {
    alt: 'l22',
    index: 22,
    src: l22,
  },
  {
    alt: 'l23',
    index: 23,
    src: l23,
  },
  {
    alt: 'l24',
    index: 24,
    src: l24,
  },
  {
    alt: 'l25',
    index: 25,
    src: l25,
  },
  {
    alt: 'l26',
    index: 26,
    src: l26,
  },
  {
    alt: 'l27',
    index: 27,
    src: l27,
  },
  {
    alt: 'l28',
    index: 28,
    src: l28,
  },
  {
    alt: 'l29',
    index: 29,
    src: l29,
  },
  {
    alt: 'l30',
    index: 30,
    src: l30,
  },
  {
    alt: 'l31',
    index: 31,
    src: l31,
  },
  {
    alt: 'l32',
    index: 32,
    src: l32,
  },
  {
    alt: 'l33',
    index: 33,
    src: l33,
  },
  {
    alt: 'l34',
    index: 34,
    src: l34,
  },
  {
    alt: 'l35',
    index: 35,
    src: l35,
  },
  {
    alt: 'l36',
    index: 36,
    src: l36,
  },
  {
    alt: 'l37',
    index: 37,
    src: l37,
  },
  {
    alt: 'l38',
    index: 38,
    src: l38,
  },
];

export default logos;
