import React from 'react';
import {
  Link,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import Icons from '../../assets/img/icons';
import Images from '../../assets/img/images';

const BoxFooterCustom = styled(Stack)(() => ({
  backgroundAttachment: 'scroll',
  backgroundColor: 'transparent',
  backgroundImage: `url(${Images.footer})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  position: 'relative',
  textAlign: 'center',
  height: 429,
}));

const StackCustom = styled(Stack)(() => ({
  background: 'rgba(0, 0, 0, 0.65)',
  width: '100%',
  height: '100%',
  paddingTop: 87,
  paddingBottom: 48,
}));

const BoxCopyright = styled(Stack)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'black',
  color: 'white',
  height: 73,
}));

const TypographyCustom = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 'bold',
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    fontSize: 14,
  },
}));

function Footer() {
  return (
    <div>
      <BoxFooterCustom>
        <StackCustom>
          <Stack>
            <Link href="/src/pages/pages">
              <img
                src={Icons.logoWhite}
                alt="Xpress Logo"
                width="auto"
                height="150"
              />
            </Link>
          </Stack>

          <Stack
            mt={4.5}
            mb={5.5}
            direction="row"
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Link
              href="https://www.facebook.com/xpressbo"
            >
              <img
                src={Icons.facebookGreen}
                alt="Facebook Logo"
                width="auto"
                height="26px"
              />
            </Link>
            <Link
              href="https://www.instagram.com/xpressbo"
            >
              <img
                src={Icons.instagramGreen}
                alt="Instagram Logo"
                width="auto"
                height="26px"
              />
            </Link>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2, sm: 4, md: 7 }}
            justifyContent="center"
            alignItems="center"
          >
            <Link
              href="/policy-and-privacy"
              underline="none"
              color="white"

            >
              <Typography variant="body1">
                Políticas de privacidad
              </Typography>
            </Link>
            <Link
              href="/terms-and-conditions"
              underline="none"
              color="white"
              style={{ paddingLeft: '12px' }}
            >
              <Typography variant="body1">
                Términos & condiciones
              </Typography>
            </Link>
          </Stack>
        </StackCustom>
      </BoxFooterCustom>
      <BoxCopyright>
        <TypographyCustom>
          © 2022 XPRESS TECHNOLOGIES. ALL RIGHTS RESERVED.
        </TypographyCustom>
      </BoxCopyright>
    </div>
  );
}

export default Footer;
