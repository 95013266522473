import { Environment } from './type';
import DevEnv from './dev';
import TestEnv from './test';
import ProductionEnv from './production';

const Environments: Record<'dev' | 'test' | 'production', Environment> = {
  dev: DevEnv,
  test: TestEnv,
  production: ProductionEnv,
};

export default Environments.production;
