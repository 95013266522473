import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../constants';
import { Nullable } from '../types';
import Settings from '../dto/settings';

export const useAgreements = ():Nullable<Settings> => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SETTINGS,
      doc: 'agreements',
      storeAs: 'agreements',
    },
  ]);
  const agreements = useSelector((state:any) => state.firestore.ordered.agreements);
  if (agreements) return agreements[0];
  return null;
};
