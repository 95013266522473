import { Base } from '../firebaseBase';
import { Constructor } from '../../types';

export default class MenuSection extends Base {
  id: string;

  enable: boolean;

  name: string;

  template: boolean;

  template_id: string;

  constructor({
    id = '',
    enable = true,
    name = '',
    template = false,
    template_id = '',
  }:Constructor<MenuSection>) {
    super();
    this.id = id;
    this.enable = enable;
    this.name = name;
    this.template = template;
    this.template_id = template_id;
  }
}
