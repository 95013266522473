import React, { useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  ButtonGroup,
  Divider,
  Link,
  CircularProgress,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import IconButton from '../../components/button/onlyIconButton';
import RoundedWideButton from '../../components/button/roundedWideButton';
import customTheme from '../../theme';
import Modal from '../../components/modal/tempIndex';
import InfoSubsidiaryModal from '../../components/modal/infoSubsidiaryModal';
import subsidiaryHooks from '../../hooks/useSubsidiary';
import ListMenuView from './listMenu';
import utilsDate from '../../utils/date';
import { useAppSelector } from '../../hooks/redux';
import AlertMessage from '../../components/alert';
import {
  distancePickUp,
  getPickupTime,
  getDeliveryTime,
} from '../../utils/distanceUtils';
import { useRestaurantById } from '../../hooks/useRestaurants';
import { AddressModal } from '../../components/modals';

const ContainerLogo = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '210px',
  width: '50%',
  left: '35px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    width: '100%',
    left: '0px',
    display: 'flex',
    justifyContent: 'center',
  },
}));
const BoxCustom = styled(Box)(() => ({
  background: 'rgba(255,255,255,0.9)',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 100000,
}));
const Banner = styled(Box)`
  position: relative;
`;

const BackButton = styled(Box)`
  position: absolute;
  background-color: #ecfcf9;
  border-radius: 17px;
  left: 34px;
  top: 16px;
  z-index: 1;
`;

const ContainerBanner = styled(Box)`
  height: 291px;
  width: 100%;
`;

const TypographyTitle = styled(Typography)`
  font-weight: bold;
  font-size: 30px;
`;

const TypographyDirection = styled(Typography)`
  font-size: 20px;
  margin-top: 10px;
  vertical-align: middle;
`;

const ContainerInfo = styled(Grid)`
  width: 100%;
  margin-top: 44px;
`;

const ContainerInfoTime = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 22px;
`;

const InfoSubsidiary = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const MoreInforText = styled(Typography)`
  text-decoration-line: underline;
  cursor: pointer;
`;

const description = 'La dirección se encuentra fuera de la zona de cobertura';
const CustomDivider = styled(Divider)`
  height: 0px;
  background: rgba(0, 0, 0, 0.13);
`;

const OpeningInfo = styled(Box)`
  width: 60%;
  height: 40px;
  background-color: ${customTheme.palette.primary.main};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function SubsidiaryView() {
  const { restaurant_name, subsidiary_name } = useParams();
  const navigate = useNavigate();
  const isLoading = useAppSelector((store) => store.loaders.isLoading);
  const [showModal, setShowModal] = useState(false);
  const subsidiary = subsidiaryHooks.useSubsidiaryByRestSubName({
    rest_name: `${restaurant_name}`,
    sub_name: `${subsidiary_name}`,
  });
  const [openAdress, setOpenAdress] = React.useState(false);
  const subsidiaryRedux = subsidiaryHooks.useSubsidiaryFromRedux(
    subsidiary?.id,
  );
  const [service, setService] = useState(
    subsidiaryRedux?.order_services[0] || 'DELIVERY',
  );
  const restaurant = useRestaurantById(subsidiary?.restaurant_id);
  const distanceOnlyPickUp = distancePickUp(subsidiaryRedux?.location);
  const currentJourney = utilsDate.getCurrentJourney(subsidiary?.schedule);
  const allCategories = useAppSelector((store) => store.categories.allCategories);
  const selectedAddress = useAppSelector(
    (state) => state.address.selectedAddress,
  );

  const categoriesNames = useMemo(() => {
    let categoriesNamesResult: string = '';
    const categoriesName: string[] = [];
    if (allCategories && subsidiary?.categories?.length > 0) {
      subsidiary.categories.forEach((categoryId) => {
        const existsCategory = allCategories.find(
          (category) => category.id === categoryId,
        );
        if (existsCategory) {
          categoriesName.push(existsCategory.name);
        }
      });
      categoriesNamesResult = categoriesName.join(' | ');
    }
    return categoriesNamesResult;
  }, [allCategories, subsidiary?.categories]);

  const outOfCity = () => {
    if (!isLoading) {
      const rest = selectedAddress && !subsidiaryRedux;
      return rest;
    }
    return false;
  };

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <Box>
      <Banner>
        <BackButton>
          <IconButton
            color="secondary"
            disabled={false}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </BackButton>
        <ContainerBanner>
          <Box
            height="100%"
            width="100%"
            style={{
              backgroundImage: `url(${subsidiary?.banner_photo?.original})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </ContainerBanner>
        <ContainerLogo>
          <img
            src={subsidiary?.logo_photo?.original}
            alt="logo"
            height="120"
            width="120"
            style={{
              borderRadius: '60px',
            }}
          />
        </ContainerLogo>
      </Banner>
      <Box ml={4} mr={4} mt={2} mb={2}>
        {isLoading && (
          <BoxCustom>
            <Box mt={10}>
              <CircularProgress sx={{ color: '#56B19E' }} />
            </Box>
          </BoxCustom>
        )}
        {!isLoading && (
          <InfoSubsidiary container>
            <ContainerInfo xs={12} sm={8} item>
              <TypographyTitle variant="body2" textTransform="capitalize">
                {subsidiary && (
                  <>{`${subsidiary?.restaurant_name} - ${subsidiary?.name}`}</>
                )}
              </TypographyTitle>
              <Box width="50%">
                <TypographyDirection variant="body2">
                  <span>{subsidiary?.address}</span>
                  {restaurant?.advertising_social_networks?.url_facebook && (
                    <span style={{ verticalAlign: 'middle' }}>
                      <Link
                        href={
                          restaurant?.advertising_social_networks?.url_facebook
                        }
                        target="_blank"
                        color="secondary"
                      >
                        <FacebookOutlinedIcon style={{ marginLeft: '5px' }} />
                      </Link>
                    </span>
                  )}
                  {restaurant?.advertising_social_networks?.url_instagram && (
                    <span style={{ verticalAlign: 'middle' }}>
                      <Link
                        href={
                          restaurant?.advertising_social_networks?.url_instagram
                        }
                        target="_blank"
                        color="secondary"
                      >
                        <InstagramIcon style={{ marginLeft: '5px' }} />
                      </Link>
                    </span>
                  )}
                </TypographyDirection>
              </Box>
              <Typography variant="body2" mt={1.5}>
                {categoriesNames}
              </Typography>
              <Box display="flex" mt={1.5}>
                <ThumbUpOutlinedIcon color="primary" />
                <Typography variant="body1" ml={1}>
                  {`${Math.trunc(subsidiary?.approvalsPercentage || 0)}% |`}
                </Typography>
                <MoreInforText
                  variant="body1"
                  ml={1}
                  color="primary"
                  onClick={() => setShowModal(true)}
                >
                  Más informacion
                </MoreInforText>
              </Box>
            </ContainerInfo>
            {subsidiaryRedux && (
              <ContainerInfoTime xs={12} sm={4} item>
                <OpeningInfo mb={2}>
                  <Typography variant="body2" fontWeight="bold">
                    {currentJourney}
                  </Typography>
                </OpeningInfo>
                {service === 'DELIVERY' && (
                  <Box
                    display="flex"
                    border="solid 1px"
                    borderColor={`${customTheme.palette.secondary.main}`}
                    borderRadius="6px"
                    height="70px"
                    justifyContent="center"
                    width="100%"
                    mb={2}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      width="100%"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="body1">
                        {`Bs. ${Number.parseFloat(
                          subsidiaryRedux?.deliveryInformation.price.toString(),
                        ).toFixed(2)}`}
                      </Typography>
                      <Typography variant="body2">Precio Delivery</Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      width="100%"
                      borderLeft="solid 1px"
                      borderColor={`${customTheme.palette.secondary.main}`}
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="body1">
                        {`${getDeliveryTime(
                          subsidiaryRedux?.cooked_average,
                          subsidiaryRedux?.deliveryInformation.distance,
                        )} minutos`}
                      </Typography>
                      <Typography variant="body2">Tiempo de entrega</Typography>
                    </Box>
                  </Box>
                )}
                {service === 'PICK_UP' && (
                  <Box
                    display="flex"
                    border="solid 1px"
                    borderColor={`${customTheme.palette.secondary.main}`}
                    borderRadius="6px"
                    height="70px"
                    justifyContent="center"
                    width="100%"
                    mb={2}
                  >
                    {distanceOnlyPickUp && (
                      <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography variant="body1">
                          {`${Number.parseFloat(
                            `${distanceOnlyPickUp}`,
                          ).toFixed(2)} Km`}
                          {}
                        </Typography>
                        <Typography variant="body2">Distancia</Typography>
                      </Box>
                    )}
                    <Box
                      display="flex"
                      alignItems="center"
                      width="100%"
                      borderLeft="solid 1px"
                      borderColor={`${customTheme.palette.secondary.main}`}
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="body1">
                        {`${getPickupTime(
                          subsidiaryRedux?.cooked_average,
                        )} minutos`}
                      </Typography>
                      <Typography variant="body2">
                        Listo para recoger
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box width="100%">
                  <ButtonGroup fullWidth>
                    {subsidiaryRedux?.order_services.find(
                      (type) => type === 'DELIVERY',
                    ) && (
                      <RoundedWideButton
                        label="DELIVERY"
                        onClick={() => setService('DELIVERY')}
                        variant={
                          service === 'DELIVERY' ? 'contained' : 'outlined'
                        }
                        color="secondary"
                        style={{ color: service === 'DELIVERY' ? 'white' : '' }}
                      />
                    )}
                    {subsidiaryRedux?.order_services.find(
                      (type) => type === 'PICK_UP',
                    ) && (
                      <RoundedWideButton
                        label="PICK UP"
                        onClick={() => setService('PICK_UP')}
                        variant={
                          service === 'PICK_UP' ? 'contained' : 'outlined'
                        }
                        style={{ color: service === 'PICK_UP' ? 'white' : '' }}
                        color="secondary"
                      />
                    )}
                  </ButtonGroup>
                </Box>
              </ContainerInfoTime>
            )}
            {!selectedAddress && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <AlertMessage
                  description="Queremos comprobar que este local entrega a su ubicación"
                  actionText="Ingresar"
                  onClick={() => {
                    setOpenAdress(true);
                  }}
                  title="Ingresa tu dirección"
                />
              </Box>
            )}
            {outOfCity() && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <AlertMessage
                  color="#c54949"
                  textColor="white"
                  actionText="Cambiar dirección"
                  description={description}
                  onClick={() => {
                    setOpenAdress(true);
                  }}
                  title="Dirección fuera de rango"
                />
              </Box>
            )}
          </InfoSubsidiary>
        )}
      </Box>
      <CustomDivider variant="fullWidth" />
      <Box ml={4} mr={4} mt={3} mb={7}>
        {subsidiary && (
          <ListMenuView
            menuSection={subsidiary?.menu_section}
            subsidiaryId={subsidiary?.id}
          />
        )}
      </Box>
      {showModal && (
        <Modal handleClose={() => handleCloseModal()}>
          <InfoSubsidiaryModal
            subsidiary_name={subsidiary?.name}
            restaurant_name={subsidiary?.restaurant_name}
            address={subsidiary?.address}
            approvalsPercentage={subsidiary?.approvalsPercentage}
            schedule={subsidiary?.schedule}
            location={subsidiary?.location}
            onClose={() => handleCloseModal()}
            categoriesNames={categoriesNames}
            socialNetworks={restaurant?.advertising_social_networks}
          />
        </Modal>
      )}

      <AddressModal
        open={openAdress}
        onClose={() => {
          setOpenAdress(false);
        }}
      />
    </Box>
  );
}

export default SubsidiaryView;
