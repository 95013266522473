import {
  FieldValue, GeoPoint, serverTimestamp, Timestamp,
} from 'firebase/firestore';
import lodash from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export function toPlainObject(object:any) {
  const data:Record<string, any> = {};
  Object.entries(object).forEach(([property, value]) => {
    if (value instanceof GeoPoint || value instanceof Timestamp) {
      data[property] = value;
    } else if (lodash.isArray(value)) {
      data[property] = value.map((item:any) => toPlainObject(item));
    } else if (lodash.isDate(value)) {
      data[property] = value;
    } else if (lodash.isObject(value)) {
      data[property] = toPlainObject(value);
    } else {
      data[property] = value;
    }
  });
  return data;
}

export function toJsonObject(object:any):Object {
  const data:Record<string, any> = {};
  Object.entries(object).forEach(([property, value]) => {
    if (value instanceof FieldValue) {
      data[property] = undefined;
    } else {
      data[property] = value;
    }
  });
  return JSON.parse(JSON.stringify(data));
}

export function toServerTimestamp(value:any): Timestamp | FieldValue {
  if (value.seconds && value.nanoseconds) {
    return new Timestamp(value.seconds, value.nanoseconds);
  }
  if (value instanceof Timestamp) {
    return value;
  }
  return serverTimestamp();
}
