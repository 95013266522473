import {
  Box, Grid, Typography, styled,
} from '@mui/material';
import * as React from 'react';
import { Modal } from './Modal';

type DialogProps = {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: () => void;
};

const ContainerImages = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ContainerBanner = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '270px',
    height: '270px',
  },
}));

const ContainerInfo = styled(Box)(({ theme }) => ({
  paddingTop: '70px',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '10px',
  },
}));

const ContainerShop = styled(Box)(({ theme }) => ({
  paddingTop: '80px',
  display: 'flex',
  justifyContent: 'space-around',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '15px',
  },
}));

export function InstallersModal({ open, onClose }: DialogProps) {
  return (
    <Modal onClose={onClose} open={open} aceptButton={false}>
      <Box sx={{ flexGrow: 1 }}>
        <ContainerImages>
          <ContainerBanner>
            <img
              src="/assets/img/celulares.png"
              width="100%"
              height="100%"
              alt="Banner Xpress"
            />
          </ContainerBanner>
          <Grid item>
            <ContainerInfo>
              <Typography
                fontWeight="bold"
                sx={{ fontSize: 20, textAlign: 'center' }}
              >
                Si desear realizar un pedido, descarga Xpress y disfruta de
                toda la eficiencia, facilidad, y conveniencia de nuestros
                servicios
              </Typography>

              <ContainerShop>
                <Grid item>
                  <a href="https://play.google.com/store/apps/details?id=com.believesol.xpress">
                    <img
                      src="/assets/img/googleplay.png"
                      width="auto"
                      height="70"
                      alt="xpress-googleplay"
                    />
                  </a>
                </Grid>
                <Grid item>
                  <a href="https://apps.apple.com/bo/app/xpress/id1507805959">
                    <img
                      src="/assets/img/appstore.png"
                      width="auto"
                      height="70"
                      alt="xpress-appstore"
                    />
                  </a>
                </Grid>
              </ContainerShop>
            </ContainerInfo>
          </Grid>
        </ContainerImages>
      </Box>
    </Modal>
  );
}

export default InstallersModal;
