import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

function DownloadApps() {
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      className={media ? 'section-apps' : undefined}
      sx={{ height: media ? '100vh' : undefined }}
    >
      <Grid
        item
        container
        xs={12}
        sm={6}
        justifyContent="space-around"
        alignItems="center"
        paddingY={3}
      >
        <img
          src="./assets/img/celulares.png"
          alt="Banner Xpress"
          style={{ height: media ? undefined : '40vh', objectFit: 'cover' }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        container
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sx={{ textAlign: 'center', paddingX: 2 }}>
          <h3>
            Descarga Xpress y disfruta de toda la
            {' '}
            <strong>eficiencia, facilidad, y conveniencia</strong>
            {' '}
            de nuestros
            servicios
          </h3>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item sx={{ marginY: media ? 10 : 3 }}>
            <a href="https://play.google.com/store/apps/details?id=com.believesol.xpress">
              <img
                src="./assets/img/googleplay.png"
                width="auto"
                height="70"
                alt="xpress-googleplay"
              />
            </a>
          </Grid>
          <Grid item>
            <a href="https://apps.apple.com/bo/app/xpress/id1507805959">
              <img
                src="./assets/img/appstore.png"
                width="auto"
                height="70"
                alt="xpress-appstore"
              />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DownloadApps;
