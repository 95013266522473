/* eslint-disable */
import React, { Component } from 'react';
import './Header.css';
import {Box} from "@mui/material";

export default class Header extends Component {
  render() {
    return (
      <Box>
        <Box>

          <div className="titulo">Memoria</div>
          <div className="titulo">
            Intentos:
            {' '}
            {this.props.numeroDeIntentos}
          </div>
        </Box>
        <Box>

          <div>
            <button className="boton-reiniciar" onClick={this.props.resetearPartida}>
              Reiniciar
            </button>
          </div>
        </Box>
      </Box>
    );
  }
}
