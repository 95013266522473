import { Route } from 'react-router-dom';
import React from 'react';
import InviteView from '../../pages/invite';

const InviteRoutes = (
  <Route path="invite">
    <Route index element={<InviteView />} />
    <Route path=":inviteId" element={<InviteView />} />
  </Route>
);

export default InviteRoutes;
