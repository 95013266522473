import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Typography, Avatar, useMediaQuery, Tooltip,
} from '@mui/material';
import { useRestaurantsBannersForXpress } from '../../hooks/useRestaurants';
import Images from '../../assets/img/images';

const CommerceContainer = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${Images.commerceSection})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'right top',
  height: '335px',
  paddingBlockStart: '60px',
  paddingBlockEnd: '70px',
  paddingInline: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '50px',
  [theme.breakpoints.down('xs')]: {
    paddingBlockStart: '30px',
  },
}));

const AvatarContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 90px;
  @media (max-width: 1100px) {
    gap: 70px;
  }
  @media (max-width: 700px) {
    gap: 50px;
  }
  @media (max-width: 600px) {
    gap: 35px;
  }
  @media (max-width: 450px) {
    gap: 25px;
  }
`;

export function CommerceSection() {
  const xpress_dashboard = useRestaurantsBannersForXpress();
  const isMobile = useMediaQuery('(max-width:450px)');

  const commercesAvatars = useMemo(() => {
    if (xpress_dashboard) {
      const { restaurants } = xpress_dashboard;
      if (restaurants && restaurants.length > 0) {
        return restaurants.slice(0, isMobile ? 4 : 6);
      }
    }
    return [];
  }, [xpress_dashboard, isMobile]);

  return (
    <CommerceContainer>
      <Typography variant={isMobile ? 'body2' : 'h3'} style={{ textAlign: 'center' }}>
        <strong>
          En XPRESS puedes encontrar una gran variedad de comercios grandes, medianos y pequeños
        </strong>
      </Typography>
      <AvatarContainer>
        {commercesAvatars?.map((com:any) => (
          <Tooltip title={com.name} placement="bottom">
            <Avatar
              alt={`commerce-${com.id}`}
              src={com.photo}
              sx={{ width: isMobile ? 82 : 110, height: isMobile ? 82 : 110 }}
            />
          </Tooltip>
        ))}
      </AvatarContainer>
    </CommerceContainer>
  );
}

export default CommerceSection;
