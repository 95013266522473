import * as React from 'react';

import { makeStyles } from '@mui/styles';
import { Modal } from './Modal';
import { IconTextField } from '../textfield';

 type DialogProps = {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onAcepte?: (term:string) => void;
  onClose: () => void;

};

const useStyles = makeStyles(() => ({
  root: {
  },
  title: {
    paddingBottom: '20px',
    textAlign: 'center',
  },
  btn: {

    background: '#FFDF00 !important',
    borderRadius: '20px !important',
    color: '#BF8D20 !important',

  },
  search: {
    /* shadow-new-effect */

    boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
}));

export function SearchModal({
  open, onClose, onAcepte = () => {},
}: DialogProps) {
  const classes = useStyles();
  const [term, setSertm] = React.useState('');

  const handleConfirm = () => {
    onAcepte(term);
  };

  const onChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setSertm(event.target.value);
  };

  return (

    <Modal onClose={onClose} onAcepte={handleConfirm} open={open} title="Buscar">
      <IconTextField
        fullWidth
        className={classes.search}
        placeholder="Buscar"
        onChange={onChange}
      />
    </Modal>
  );
}

export default SearchModal;
