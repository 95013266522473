import React from 'react';
import { Dialog } from '@mui/material';

interface OnlyModalProps {
  handleClose: () => void,
  children?: React.ReactNode
}

function CustomModal({ handleClose, children }: OnlyModalProps) {
  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ backgroundColor: 'rgb(31, 100, 86, 0.44)', padding: '0px !important' }}
      maxWidth={false}
    >
      {children}
    </Dialog>
  );
}

export default CustomModal;
