import { createSlice } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';

type State = {
  value:number
}

const initialState: State = {
  value: 0,
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
});

export const { increment, decrement, incrementByAmount } = userSlice.actions;
export default userSlice.reducer;
export const userReducerTransform = createTransform(
  (toSave:State) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
  }),
  { whitelist: ['users'] },
);
