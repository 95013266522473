import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../constants';
import Product from '../dto/product';
import { Nullable } from '../types';

export const useMenuBySubsidiary = (
  subsidiaryId: string,
): Nullable<Product[]> => {
  if (subsidiaryId) {
    useFirestoreConnect([
      {
        collection: COLLECTIONS.PRODUCT,
        where: [
          ['subsidiary_id', '==', subsidiaryId],
          ['enable', '==', true],
          ['availability', '==', true],
        ],
        storeAs: `subsidiary_products_${subsidiaryId}`,
      },
    ]);

    const products = useSelector(
      (state: any) => state.firestore.ordered[`subsidiary_products_${subsidiaryId}`],
    );

    if (products) {
      return products.map((city: any) => new Product({ ...city }));
    }

    return null;
  }
  return null;
};

export default useMenuBySubsidiary;
