import React, { useEffect } from 'react';
import {
  BrowserRouter, Route, Routes, useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import LandingPage from '../pages/landing';
import HomeView from '../pages/welcome';
import RedirectExternal from '../components/redirect/Redirect';
import CookiesDeclaration from '../pages/terms/CookiesDeclaration';
import DriverAgreement from '../pages/terms/DriverAgreement';
import TermsAndConditions from '../pages/terms/TermsAndConditions';
import PolicyAndPrivacy from '../pages/terms/PolicyAndPrivacy';
import Agreement from '../pages/terms/Agreement';
import Error404View from '../pages/Error404View';
import RootRoutes from './root';
import InviteRoutes from './invite';
import RestaurantRoutes from './restaurant';
import TopicsRoutes from './topics';
import env from '../env';
import Ahorcado from '../games/ahorcado/src/Components/AhorcadoBase';
import SnakeGame from '../games/snake/src/SnakeGame';
import MemoryGame from '../games/memoria/src';
import RequestDeleteAccount from '../pages/requestDeleteAccount';

ReactGA.initialize(env.googleAnalytics);

function CustomRoute() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);
  return (
    <Routes>
      {RootRoutes}
      {InviteRoutes}
      {TopicsRoutes}
      {RestaurantRoutes}
      <Route path="welcome" element={<LandingPage />} />
      <Route path="commerce" element={<HomeView />} />
      <Route path="installers" element={<RedirectExternal />} />
      <Route path="cookies-declaration" element={<CookiesDeclaration />} />
      <Route path="driver-agreement" element={<DriverAgreement />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="policy-and-privacy" element={<PolicyAndPrivacy />} />
      <Route path="acuerdo" element={<Agreement />} />
      <Route path="404" element={<Error404View />} />
      <Route path="games/ahorcado" element={<Ahorcado />} />
      <Route path="games/snake" element={<SnakeGame />} />
      <Route path="games/memoria" element={<MemoryGame />} />
      <Route path="request-delete-account" element={<RequestDeleteAccount />} />
    </Routes>
  );
}
function AppRoutes() {
  return (
    <BrowserRouter>
      <CustomRoute />
    </BrowserRouter>
  );
}

export default AppRoutes;
