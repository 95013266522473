import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DownloadApps from '../downloadApps/DownloadApps';
import { deepLink, isMobile } from '../../utils/deepLink';

export function Redirect() {
  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      if (isMobile()) {
        deepLink.open(/* location.search */);
      } else {
        alert('Please, open this link in a phone');
      }
    }
  }, []);
  return <DownloadApps />;
}

export default Redirect;
