import React/* , { useState } */ from 'react';
import {
  Stack, Avatar, Typography, styled,
} from '@mui/material';
import Icons from '../../assets/img/icons';

interface CategoryProps {
  id: string,
  alt?:string,
  src: string,
  label: string,
  isSelected:boolean
  handleClick:(value: string) => void,
}

const AvatarCustom = styled(Avatar)`
  width: 90px;
  height: 90px;
  cursor: pointer;
  margin-bottom: 10px;
  box-sizing: border-box;
  @media (max-width: 450px) {
    width: 66px;
    height: 66px;
    margin-bottom: 5px;
  }
`;

const TypographyCustom = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: 14,
  [theme.breakpoints.up('xs')]: {
    fontSize: 18,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 21,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 23,
  },
}));

export function Category({
  id,
  alt = 'category',
  src,
  label = '-',
  isSelected,
  handleClick,
}: CategoryProps) {
  return (
    <Stack justifyContent="center" alignItems="center">
      <AvatarCustom
        key={id}
        sx={
          isSelected
            // eslint-disable-next-line max-len
            ? { border: '5px solid #56b19e', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.50)' }
            : { border: '' }
        }
        alt={alt}
        src={src || Icons.food}
        onClick={() => handleClick(id)}
      />
      <TypographyCustom>
        {label}
      </TypographyCustom>
    </Stack>
  );
}

export default Category;
