import { LINK_APPS } from '../constants';

const isAndroid = () => navigator.userAgent.match('Android');

const isIOS = () => (
  navigator.userAgent.match('iPad')
    || navigator.userAgent.match('iPhone')
    || navigator.userAgent.match('iPod')
);

export const isMobile = () => isAndroid() || isIOS();

const open = () => {
  if (isIOS()) {
    const iosLaunchApp = () => {
      setTimeout(() => {
        window.location.href = LINK_APPS.ios;
      }, 25);
      // window.location.href = uri;
    };
    iosLaunchApp();
  } else if (isAndroid()) {
    const openWebApp = () => {
      window.location.href = LINK_APPS.android;
    };
    const androidLaunchApp = () => {
      // window.location.href = uri;
      setTimeout(openWebApp, 500);
    };
    androidLaunchApp();
  }
};

export const deepLink = {
  open,
};
