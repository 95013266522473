import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  styled,
  Typography,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import utilsDate from '../../utils/date';
import customTheme from '../../theme';

const CustomButton = styled(Box)(() => ({
  background: '#FDD6CE',
  borderRadius: '14px',
  color: customTheme.palette.warning.main,
}));

const CustomTableContainer = styled(TableContainer)(() => ({
  width: '95%',
}));

interface ScheduleDateProps {
  end_time: Timestamp
  start_time: Timestamp
}

interface ScheduleProps {
  open: boolean
  schedule: Array<ScheduleDateProps>
  journey: string
}

interface InfoSubsidiaryProps {
  schedule: Array<ScheduleProps>
}

function ScheduleOnlyView({ schedule }: InfoSubsidiaryProps) {
  const handleJourney = (currentJourney: ScheduleDateProps) => {
    if (currentJourney) {
      const newValue = utilsDate.getJourneyDay(currentJourney);
      return newValue;
    }
    return null;
  };

  return (
    <CustomTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              style={{
                borderColor: customTheme.palette.secondary.main,
                borderRight: `1px solid ${customTheme.palette.secondary.main}`,
                fontWeight: 'bold',
                padding: 5,
              }}
            >
              Días
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderColor: customTheme.palette.secondary.main,
                borderRight: `1px solid ${customTheme.palette.secondary.main}`,
                fontWeight: 'bold',
                padding: 5,
              }}
            >
              Jornada 1
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderColor: customTheme.palette.secondary.main,
                fontWeight: 'bold',
                padding: 5,
              }}
            >
              Jornada 2
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedule.map((row) => (
            <TableRow
              key={row.journey}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                align="center"
                style={{
                  borderColor: customTheme.palette.secondary.light,
                  borderRight: `1px solid ${customTheme.palette.secondary.main}`,
                  padding: 5,
                }}
              >
                <Typography
                  variant="body2"
                  style={{ color: row.open ? '' : customTheme.palette.warning.main }}
                >
                  {row.journey}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderColor: customTheme.palette.secondary.main,
                  borderRight: `1px solid ${customTheme.palette.secondary.main}`,
                  padding: 5,
                }}
              >
                {row.open ? (handleJourney(row?.schedule[0]) || '----') : (
                  <CustomButton sx={{ padding: 1 }}>
                    Cerrado
                  </CustomButton>
                )}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderColor: customTheme.palette.secondary.main,
                  padding: 5,
                }}
              >

                {row.open ? (handleJourney(row?.schedule[1]) || '----') : (
                  <CustomButton sx={{ padding: 1 }}>
                    Cerrado
                  </CustomButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
}

export default ScheduleOnlyView;
