import Axios from 'axios';
import env from '../../env';
import { FUNCTIONS_URLS } from '../../constants';

const AuthService = {
  // eslint-disable-next-line class-methods-use-this
  getVerificationCode: async (phoneNumber: string) => new Promise((resolve, reject) => {
    Axios.get(
      env.cloudFunctionsUrl
                + FUNCTIONS_URLS.GET_VERIFICATION_CODE
                + phoneNumber,
      {},
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  }),

  verifyCode: async (phoneNumber: string, code: string) => new Promise((resolve, reject) => {
    Axios.get(
      `${env.cloudFunctionsUrl
                + FUNCTIONS_URLS.VERIFY_CODE
                + phoneNumber
      }/${
        code}`,
      {},
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  }),

  handleDeleteUser: async (userId: string) => {
    const result = await Axios.post(env.cloudFunctionsUrl + FUNCTIONS_URLS.DELETE_USER, {
      data: {
        userId,
      },
    });
    return result.data;
  },
};

export default AuthService;
