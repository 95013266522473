// eslint-disable-next-line max-classes-per-file
import FirebaseBase from '../firebaseBase';
import { Constructor } from '../../types';
import Photo from '../photo';
import ProductCategory from '../productCategory';
import MenuSection from '../menuSection';

export class Product extends FirebaseBase {
  enable: boolean;

  subsidiary_id: string;

  gallery_id: string;

  restaurant_id: string;

  name: string;

  cooking_time: number;

  category: ProductCategory;

  unit_price: number;

  menu_section_id: string;

  menu_section:MenuSection;

  pre_packed: boolean;

  availability: boolean;

  description: string;

  photo: Photo;

  menu_order: number;

  constructor({
    enable = true,
    subsidiary_id = '',
    gallery_id = '',
    restaurant_id = '',
    name = 'Nuevo Producto',
    cooking_time = 10,
    category = new ProductCategory({}),
    unit_price = 0,
    menu_section = new MenuSection({}),
    menu_section_id = '',
    pre_packed = false,
    availability = false,
    description = '',
    photo = new Photo({}),
    menu_order = 0,
    ...data
  }: Constructor<Product>) {
    super(data);
    this.enable = enable;
    this.subsidiary_id = subsidiary_id;
    this.restaurant_id = restaurant_id;
    this.name = name;
    this.cooking_time = Number(cooking_time);
    this.category = category ? new ProductCategory(category) : category;
    this.unit_price = Number(unit_price);
    this.menu_section_id = menu_section_id;
    this.pre_packed = pre_packed;
    this.availability = availability;
    this.description = description;
    this.photo = photo ? new Photo(photo) : photo;
    this.menu_order = Number(menu_order);
    this.gallery_id = gallery_id;
    this.menu_section = menu_section;
  }
}

export default Product;
