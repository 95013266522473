import { Box, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import RestaurantProductCard from '../../components/card/restaurantProductCard';
import CategoryButtons from '../../components/categoryButtons';
import InstallersModal from '../../components/modals/InstallersModal';
import MenuSection from '../../dto/menuSection';
import Product from '../../dto/product';
import ProductCategory from '../../dto/productCategory';
import useMenuBySubsidiary from '../../hooks/useMenu';
import { Nullable } from '../../types';

function getCategories(sections: MenuSection[], products: Nullable<Product[]>) {
  const localCategory: ProductCategory[] = [];
  sections?.forEach((section) => {
    const results = products?.filter(
      (product) => product.menu_section_id === section.id,
    );
    if (results && results.length) {
      localCategory.push(
        new ProductCategory({
          label: section.name,
          value: section.id,
        }),
      );
    }
  });
  return localCategory;
}

const defaultImage = '/assets/img/default_product_image.jpg';
type ListMenuProps = {
  subsidiaryId: string;
  menuSection: MenuSection[];
};
function ListMenuView({ subsidiaryId, menuSection }: ListMenuProps) {
  const products = useMenuBySubsidiary(subsidiaryId);
  const [selectedProduct, setSelectedProduct] = React.useState<Product[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState('all');
  const [selectedCategoryLabel, setSelectedCategoryLabel] = React.useState('');
  const [dialog, setDialog] = React.useState(false);

  const changeCategory = (category: ProductCategory) => {
    setSelectedCategory(category.value);
    setSelectedCategoryLabel(category.label);
    let localProducts: Product[] = [];
    if (products) {
      if (category.value !== 'all') {
        localProducts = products?.filter(
          (product) => product.menu_section_id === category.value,
        );
      } else {
        localProducts = [...products];
      }
      setSelectedProduct([...localProducts]);
    }
  };
  const categories = useMemo(() => {
    const menuSectionEnable = menuSection.filter((section) => section.enable);
    if (products) {
      return getCategories(menuSectionEnable, products);
    }
    return [];
  }, [menuSection, products]);

  if (products == null) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress disableShrink />
      </Grid>
    );
  }

  if (products.length === 0) {
    return (
      <Grid container justifyContent="center">
        <Typography
          fontWeight="bold"
          variant="h5"
        >
          No hay productos disponible para mostrar
        </Typography>
      </Grid>
    );
  }
  return (
    <Box>
      {products && (
        <CategoryButtons
          options={categories}
          onClick={(option:ProductCategory) => changeCategory(option)}
        />
      )}
      {selectedCategory === 'all' ? (
        <Box>
          {categories.map((cat, index) => (
            <Box key={cat.value + index.toString()}>
              <Typography
                fontWeight="bold"
                variant="h5"
                mt={3}
                mb={2}
                textTransform="capitalize"
              >
                {cat.label}
              </Typography>

              <Grid container direction="row" spacing={2}>
                {products
                  ?.filter((prod) => prod.menu_section_id === cat.value)
                  ?.map((prod) => (
                    <Grid key={`${prod.id}all`} xs={12} sm={3} item width="100%">
                      <RestaurantProductCard
                        id={prod.id}
                        name={prod.name}
                        description={prod.description}
                        price={prod.unit_price}
                        src={
                          prod.photo.original ? prod.photo.x400 : defaultImage
                        }
                        onClick={() => setDialog(true)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          <Typography
            fontWeight="bold"
            variant="h5"
            mt={3}
            mb={2}
            textTransform="capitalize"
          >
            {selectedCategoryLabel}
          </Typography>
          <Grid container spacing={2} direction="row" marginTop={3}>

            {selectedProduct?.map((prod) => (
              <Grid key={`${prod.id}selected`} xs={12} sm={3} item width="100%">
                <RestaurantProductCard
                  id={prod.id}
                  name={prod.name}
                  description={prod.description}
                  price={prod.unit_price}
                  src={prod.photo.original ? prod.photo.x400 : defaultImage}
                  onClick={() => setDialog(true)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <InstallersModal open={dialog} onClose={() => setDialog(false)} />
    </Box>
  );
}

export default ListMenuView;
