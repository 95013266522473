/* import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css'; */
import './config/firebase';
import './assets/css/prism.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

window.onbeforeunload = () => {
  window.scrollTo(0, 0);
};

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
