import { TIME_ZONES } from '../../constants';
import { axiosFunctions } from '../../utils/axios';
import HomeModel from '../../models/home/models/homeModel';

type GetHomeDataParams = {
  location:{latitude:number, longitude:number}
  timeZoneOffset?:number
  userId?:string
}
export const MobileServices = {
  async getHomeData({
    location, timeZoneOffset = TIME_ZONES.BOLIVIA, userId = '',
  }:GetHomeDataParams) {
    const body = {
      location, timeZoneOffset, userId,
    };
    const { data } = await axiosFunctions.post('mobile/getHomeData', { data: body });
    if (data.data.isSucceeded) {
      return new HomeModel(data.data.data);
    }
    throw new Error(data.message);
  },
};
