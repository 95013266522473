/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { styled } from '@mui/material/styles';
import {
  FormControl, InputLabel, Select, MenuItem, FormHelperText,
} from '@mui/material';

const CustomizedSelect = styled(FormControl)({
  boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
  borderRadius: '20px',
  backgroundColor: 'white',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '20px',
    },
  },
});

const SimpleSelect = styled(Select)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    borderRadius: 20,
    position: 'relative',
    boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'white',
    padding: '15px 19px',
  },
  '&.Mui-focused fieldset': { borderColor: 'transparent' },
}));

type selectOption = {
  id: string;
  name: string;
};
export type TextFieldWithIconProps = {
  id: string,
  name: string,
  disabled?: boolean,
  error?:boolean,
  fullWidth?:boolean,
  helperText?:React.ReactNode,
  label?:string,
  // eslint-disable-next-line no-unused-vars
  onChange?:(event: any) => void,
  required?:boolean,
  value?:string,
  selectList: selectOption[],
  variant?:'filled'
  | 'outlined'
  | 'standard',
}

export function SelectRounded({
  id,
  name,
  disabled,
  error,
  fullWidth,
  helperText,
  label,
  onChange,
  required,
  value,
  selectList,
  variant,
}: TextFieldWithIconProps) {
  return (
    <CustomizedSelect
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
      required={required}
    >
      <InputLabel id={`label-${id}`}>{label}</InputLabel>
      <Select
        labelId={`label-${id}`}
        id={id}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
      >
        {selectList.map((item) => (
          <MenuItem key={`id-${item.id}`} value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </CustomizedSelect>
  );
}

export function SelectSimpleRounded({
  id,
  name,
  disabled,
  error,
  fullWidth,
  helperText,
  label,
  onChange,
  required,
  value,
  selectList,
  variant,
}: TextFieldWithIconProps) {
  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
      required={required}
    >
      <InputLabel
        id={`label-${id}`}
        style={{ color: 'white', fontWeight: 'bold' }}
      >
        {label}
      </InputLabel>
      <SimpleSelect
        labelId={`label-${id}`}
        id={id}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
      >
        {selectList.map((item) => (
          <MenuItem key={`id-${item.id}`} value={item.id}>{item.name}</MenuItem>
        ))}
      </SimpleSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectRounded;
