import React from 'react';
import CategoriesProvider from './categories';
import CitiesProvider from './cities';
import { HomeProvider } from './home';

type AppProvidersProps = {
  children: any
}

export default function AppProviders({ children }:AppProvidersProps) {
  return (
    <CitiesProvider>
      <CategoriesProvider>
        <HomeProvider>
          {children}
        </HomeProvider>
      </CategoriesProvider>
    </CitiesProvider>
  );
}
