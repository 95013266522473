import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Grid, Stack, Typography, useMediaQuery, CircularProgress,
} from '@mui/material';
import { OldPage } from '../../components';
import { RestaurantCard } from '../../components/card/restaurantCard';
import { useAppSelector } from '../../hooks/redux';
import { AddressModal } from '../../components/modals';
import { useRestaurantByRestName } from '../../hooks/useRestaurants';

function filterSubsidiaries(subsidiaries, filter) {
  const result:any[] = [];
  if (subsidiaries) {
    subsidiaries.forEach((subsidiary) => {
      const aux:Record<string, any> = {
        restaurant_name: subsidiary.restaurant_name,
        subsidiary_name: subsidiary.name,
      };
      const json = JSON.stringify(aux).toLowerCase();
      const conditionFilter = filter !== '' ? json.includes(filter.toLowerCase()) : false;
      if (conditionFilter) {
        result.push(subsidiary);
      }
    });
  }
  return result;
}

function RestaurantByLink() {
  const navigate = useNavigate();
  const { restaurant_name } = useParams();
  const restaurant = useRestaurantByRestName(restaurant_name);
  const isMobile = useMediaQuery('(max-width:450px)');
  const responsiveSpacing = isMobile ? 3 : 6;
  const selectedAddress = useAppSelector((store) => store.address.selectedAddress);
  const homeData = useAppSelector((store) => store.home.data);
  const subsidiaries = useAppSelector((store) => store.home.data?.subsidiaries);
  const filter = useAppSelector((store) => store.navBar.searchNavbar);
  const [openModalAddress, setOpenModalAddress] = useState(false);

  const [filteredSubsidiaries] = useMemo(() => {
    if (restaurant && subsidiaries) {
      const subsidiariesByRest = subsidiaries.filter((sub) => sub.restaurant_id === restaurant.id);
      if (filter !== '') {
        return [
          filterSubsidiaries(subsidiariesByRest, filter),
        ];
      }
      return [subsidiariesByRest];
    }
    return [null];
  }, [subsidiaries, restaurant, filter]);

  useEffect(() => {
    if (!homeData && !selectedAddress) {
      if (restaurant) setOpenModalAddress(true);
    }
  }, [restaurant]);

  const handleClikRestaurant = (normalizedName, normalizedRestaurantName) => {
    navigate(`../${normalizedRestaurantName}/${normalizedName}`, { replace: false });
  };

  return (
    <OldPage title="Restaurantes">
      <Box>
        <Stack p={responsiveSpacing}>
          <Stack mb={3}>
            <Typography variant={isMobile ? 'body2' : 'h2'}>
              <strong>
                Sucursales cercanos
              </strong>
            </Typography>
          </Stack>
          {restaurant === null && (
            <Stack justifyContent="center" alignItems="center" mb={3}>
              <CircularProgress />
            </Stack>
          )}
          {restaurant && (
            <>
              {filteredSubsidiaries && filteredSubsidiaries.length > 0 ? (
                <Grid
                  container
                  spacing={{ xs: 2, md: 3, lg: 8 }}
                  mt={responsiveSpacing}
                >
                  {filteredSubsidiaries?.map((subsidiary) => (
                    <Grid
                      item
                      key={subsidiary.id}
                      width={isMobile ? '90vw' : '100%'}
                      xs={isMobile ? 9 : 6}
                      sm={4}
                      md={3}
                    >
                      <RestaurantCard
                        src={subsidiary.banner_photo.x400 || subsidiary.banner_photo.original}
                        restaurantName={subsidiary.restaurant_name}
                        subsidiaryName={subsidiary.name}
                        categories={subsidiary.categories}
                        likes={subsidiary.likes || 0}
                        approvalsPercentage={subsidiary.approvalsPercentage || 0}
                        price={subsidiary.deliveryInformation.price}
                        distance={subsidiary.deliveryInformation.distance}
                        handleClik={() => {
                          const subName: string = subsidiary.normalized_name;
                          const restName: string = subsidiary.normalized_restaurant_name;
                          handleClikRestaurant(subName, restName);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography mt={3} variant="body1">
                  No se encontraron sucursales
                </Typography>
              )}
            </>
          )}
        </Stack>
        <AddressModal
          open={openModalAddress}
          onClose={() => {
            setOpenModalAddress(false);
          }}
        />
      </Box>
    </OldPage>
  );
}

export default RestaurantByLink;
