import { useEffect } from 'react';
import { admin } from '../../redux/store';
import { COLLECTIONS } from '../../constants';
import { City } from '../../dto';
import { useAppDispatch } from '../../hooks/redux';
import { setAllCities } from '../../redux/reducers/cities';

const useCitiesProvider = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    admin
      .firestore()
      .collection(COLLECTIONS.CITY)
      .where('enable', '==', true)
      .onSnapshot((querySnapshot) => {
        const cities: City[] = [];
        querySnapshot.forEach((doc) => {
          const city = new City({ ...doc.data(), id: doc.id });
          cities.push(city);
        });
        dispatch(setAllCities(cities));
      });
  }, []);
};
type CitiesProviderProps = {
  children: any
}
export function CitiesProvider({ children }:CitiesProviderProps) {
  useCitiesProvider();
  return children;
}
export default CitiesProvider;
