import SubsidiaryModel from './subsidiaryModel';
import { Constructor } from '../../../types';

export default class HomeModel {
  subsidiaries: SubsidiaryModel[];

  promotions: any[];

  subsidiariesWithRewards: any[];

  bannerPromotion: {};

  sponsoredProducts: any[];

  topRated: any[];

  requestedInArea: any[];

  requestedDishInArea: any[];

  favoriteRestaurants: any[];

  foodCourts: any[];

  constructor({
    subsidiaries = [],
    promotions = [],
    bannerPromotion = {},
    subsidiariesWithRewards = [],
    sponsoredProducts = [],
    topRated = [],
    requestedInArea = [],
    requestedDishInArea = [],
    favoriteRestaurants = [],
    foodCourts = [],
  }:Constructor<HomeModel>) {
    this.subsidiaries = subsidiaries.map((subsidiary:any) => new SubsidiaryModel(subsidiary));
    this.promotions = promotions;
    this.subsidiariesWithRewards = subsidiariesWithRewards;
    this.bannerPromotion = bannerPromotion; // ? new PromoBanner(bannerPromotion) : null;
    this.sponsoredProducts = sponsoredProducts;
    this.topRated = topRated.map((subsidiary:any) => new SubsidiaryModel(subsidiary));
    this.requestedInArea = requestedInArea;
    this.requestedDishInArea = requestedDishInArea;
    this.favoriteRestaurants = favoriteRestaurants;
    this.foodCourts = foodCourts;
  }
}
