import { createSlice } from '@reduxjs/toolkit';

type State = { isLoading: boolean }

const initialState: State = { isLoading: false };

export const loaderSlice = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    setLoaderListRestaurants: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoaderListRestaurants } = loaderSlice.actions;
export default loaderSlice.reducer;
