export default class PriceInformationResult {
  code: number;

  success: boolean;

  message: string;

  price: number;

  subsidy: number;

  distance: number;

  delivery_time: number;

  constructor({
    code = 0,
    success = false,
    message = '',
    price = 0,
    subsidy = 0,
    distance = 0,
    delivery_time = 0,
  }) {
    this.code = code;
    this.success = success;
    this.message = message;
    this.price = price;
    this.subsidy = subsidy;
    this.distance = distance;
    this.delivery_time = delivery_time;
  }
}
