import { Base } from '../firebaseBase';

export class ProductCategory extends Base {
  value: string;

  label: string;

  constructor({ label = '', value = '' }) {
    super();
    this.label = label;
    this.value = value;
  }
}

export default ProductCategory;
