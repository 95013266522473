/* eslint-disable */
// eslint-disable-next-line
import React from 'react';
import './SnakeGame.css';
import { Button } from '@mui/material';
import GameOver from './GameOver';
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp
} from "@mui/icons-material";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

class SnakeGame extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      width: 0,
      height: 0,
      blockWidth: 0,
      blockHeight: 0,
      gameLoopTimeout: 50,
      timeoutId: 0,
      startSnakeSize: 0,
      snake: [],
      apple: {},
      direction: 'right',
      directionChanged: false,
      isGameOver: false,
      snakeColor: this.props.snakeColor || this.getRandomColor(),
      appleColor: this.props.appleColor || this.getRandomColor(),
      score: 0,
      highScore: Number(localStorage.getItem('snakeHighScore')) || 0,
      newHighScore: false,
    };
  }

  componentDidMount() {
    this.initGame();
    window.addEventListener('keydown', this.handleKeyDown);
    this.gameLoop();
  }

  initGame() {
    // Game size initialization
    const percentageWidth = 100;
    let width = document.getElementById('GameBoard').parentElement.offsetWidth
      * (percentageWidth / 100);
    width -= width % 30;
    if (width < 30) width = 30;
    const height = (width / 3) * 2;
    const blockWidth = width / 30;
    const blockHeight = height / 20;

    // snake initialization
    const startSnakeSize = this.props.startSnakeSize || 6;
    const snake = [];
    let Xpos = width / 2;
    const Ypos = height / 2;
    const snakeHead = { Xpos: width / 2, Ypos: height / 2 };
    snake.push(snakeHead);
    for (let i = 1; i < startSnakeSize; i++) {
      Xpos -= blockWidth;
      const snakePart = { Xpos, Ypos };
      snake.push(snakePart);
    }

    // apple position initialization
    const appleXpos = Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1))
      * blockWidth;
    let appleYpos = Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1))
      * blockHeight;
    while (appleYpos === snake[0].Ypos) {
      appleYpos = Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1))
        * blockHeight;
    }

    this.setState({
      width,
      height,
      blockWidth,
      blockHeight,
      startSnakeSize,
      snake,
      apple: { Xpos: appleXpos, Ypos: appleYpos },
    });
  }

  gameLoop() {
    const timeoutId = setTimeout(() => {
      if (!this.state.isGameOver) {
        this.moveSnake();
        this.tryToEatSnake();
        this.tryToEatApple();
        this.setState({ directionChanged: false });
      }

      this.gameLoop();
    }, this.state.gameLoopTimeout);

    this.setState({ timeoutId });
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  resetGame() {
    const { width } = this.state;
    const { height } = this.state;
    const { blockWidth } = this.state;
    const { blockHeight } = this.state;
    const { apple } = this.state;

    // snake reset
    const snake = [];
    let Xpos = width / 2;
    const Ypos = height / 2;
    const snakeHead = { Xpos: width / 2, Ypos: height / 2 };
    snake.push(snakeHead);
    for (let i = 1; i < this.state.startSnakeSize; i++) {
      Xpos -= blockWidth;
      const snakePart = { Xpos, Ypos };
      snake.push(snakePart);
    }

    // apple position reset
    apple.Xpos = Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1))
      * blockWidth;
    apple.Ypos = Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1))
      * blockHeight;
    while (this.isAppleOnSnake(apple.Xpos, apple.Ypos)) {
      apple.Xpos = Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1))
        * blockWidth;
      apple.Ypos = Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1))
        * blockHeight;
    }

    this.setState({
      snake,
      apple,
      direction: 'right',
      directionChanged: false,
      isGameOver: false,
      gameLoopTimeout: 50,
      snakeColor: this.getRandomColor(),
      appleColor: this.getRandomColor(),
      score: 0,
      newHighScore: false,
    });
  }

  getRandomColor() {
    const hexa = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) color += hexa[Math.floor(Math.random() * 16)];
    return color;
  }

  moveSnake() {
    const { snake } = this.state;
    let previousPartX = this.state.snake[0].Xpos;
    let previousPartY = this.state.snake[0].Ypos;
    let tmpPartX = previousPartX;
    let tmpPartY = previousPartY;
    this.moveHead();
    for (let i = 1; i < snake.length; i++) {
      tmpPartX = snake[i].Xpos;
      tmpPartY = snake[i].Ypos;
      snake[i].Xpos = previousPartX;
      snake[i].Ypos = previousPartY;
      previousPartX = tmpPartX;
      previousPartY = tmpPartY;
    }
    this.setState({ snake });
  }

  tryToEatApple() {
    const { snake } = this.state;
    const { apple } = this.state;

    // if the snake's head is on an apple
    if (snake[0].Xpos === apple.Xpos && snake[0].Ypos === apple.Ypos) {
      const { width } = this.state;
      const { height } = this.state;
      const { blockWidth } = this.state;
      const { blockHeight } = this.state;
      const newTail = { Xpos: apple.Xpos, Ypos: apple.Ypos };
      let { highScore } = this.state;
      let { newHighScore } = this.state;
      let { gameLoopTimeout } = this.state;

      // increase snake size
      snake.push(newTail);

      // create another apple
      apple.Xpos = Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1))
        * blockWidth;
      apple.Ypos = Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1))
        * blockHeight;
      while (this.isAppleOnSnake(apple.Xpos, apple.Ypos)) {
        apple.Xpos = Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1))
          * blockWidth;
        apple.Ypos = Math.floor(
          Math.random() * ((height - blockHeight) / blockHeight + 1),
        ) * blockHeight;
      }

      // increment high score if needed
      if (this.state.score === highScore) {
        highScore++;
        localStorage.setItem('snakeHighScore', highScore);
        newHighScore = true;
      }

      // decrease the game loop timeout
      if (gameLoopTimeout > 25) gameLoopTimeout -= 0.5;

      this.setState({
        snake,
        apple,
        score: this.state.score + 1,
        highScore,
        newHighScore,
        gameLoopTimeout,
      });
    }
  }

  tryToEatSnake() {
    const { snake } = this.state;

    for (let i = 1; i < snake.length; i++) {
      if (snake[0].Xpos === snake[i].Xpos && snake[0].Ypos === snake[i].Ypos) this.setState({ isGameOver: true });
    }
  }

  isAppleOnSnake(appleXpos, appleYpos) {
    const { snake } = this.state;
    for (let i = 0; i < snake.length; i++) {
      if (appleXpos === snake[i].Xpos && appleYpos === snake[i].Ypos) return true;
    }
    return false;
  }

  moveHead(estado = this.state.direction) {
    switch (estado) {
      case 'left':
        this.moveHeadLeft();
        break;
      case 'up':
        this.moveHeadUp();
        break;
      case 'right':
        this.moveHeadRight();
        break;
      default:
        this.moveHeadDown();
    }
  }

  moveHeadLeft() {
    const { width } = this.state;
    const { blockWidth } = this.state;
    const { snake } = this.state;
    snake[0].Xpos = snake[0].Xpos <= 0 ? width - blockWidth : snake[0].Xpos - blockWidth;
    this.setState({ snake });
  }

  // mover arriba
  moveHeadUp() {
    const { height } = this.state;
    const { blockHeight } = this.state;
    const { snake } = this.state;
    snake[0].Ypos = snake[0].Ypos <= 0 ? height - blockHeight : snake[0].Ypos - blockHeight;
    this.setState({ snake });
  }

  moveHeadRight() {
    const { width } = this.state;
    const { blockWidth } = this.state;
    const { snake } = this.state;
    snake[0].Xpos = snake[0].Xpos >= width - blockWidth ? 0 : snake[0].Xpos + blockWidth;
    this.setState({ snake });
  }

  moveHeadDown() {
    const { height } = this.state;
    const { blockHeight } = this.state;
    const { snake } = this.state;
    snake[0].Ypos = snake[0].Ypos >= height - blockHeight ? 0 : snake[0].Ypos + blockHeight;
    this.setState({ snake });
  }

  handleKeyDown(event) {
    // if spacebar is pressed to run a new game
    if (this.state.isGameOver && event.keyCode === 32) {
      this.resetGame();
      return;
    }

    if (this.state.directionChanged) return;

    switch (event.keyCode) {
      case 37:
      case 65:
        this.goLeft();
        break;
      case 38:
      case 87:
        this.goUp();
        break;
      case 39:
      case 68:
        this.goRight();
        break;
      case 40:
      case 83:
        this.goDown();
        break;
      default:
    }
    this.setState({ directionChanged: true });
  }

  goLeft() {
    const newDirection = this.state.direction === 'right' ? 'right' : 'left';
    this.setState({ direction: newDirection });
  }

  goUp() {
    const newDirection = this.state.direction === 'down' ? 'down' : 'up';
    this.setState({ direction: newDirection });
  }

  goRight() {
    const newDirection = this.state.direction === 'left' ? 'left' : 'right';
    this.setState({ direction: newDirection });
  }

  goDown() {
    const newDirection = this.state.direction === 'up' ? 'up' : 'down';
    this.setState({ direction: newDirection });
  }

  render() {
    // Game over
    if (this.state.isGameOver) {
      return (
        <GameOver
          width={getWindowDimensions().width}
          height={getWindowDimensions().height}
          highScore={this.state.highScore}
          newHighScore={this.state.newHighScore}
          score={this.state.score}
          restart={()=>this.resetGame()}
        />
      );
    }

    return (
      <div style={{height:"100vh", width:"100%"}}>
        <div
            id="GameBoard"
            style={{
              width: this.state.width,
              height: this.state.height,
              borderWidth: 1
            }}
        >
          {this.state.snake.map((snakePart, index) => (
              <div
                  key={index}
                  className="Block"
                  style={{
                    width: this.state.blockWidth,
                    height: this.state.blockHeight,
                    left: snakePart.Xpos,
                    top: snakePart.Ypos,
                    background: this.state.snakeColor,
                  }}
              />
          ))}
          <div
              className="Block"
              style={{
                width: this.state.blockWidth,
                height: this.state.blockHeight,
                left: this.state.apple.Xpos,
                top: this.state.apple.Ypos,
                background: this.state.appleColor,
              }}
          />
        </div>
        <div id="Score" style={{  position:"absolute", bottom:10, width:'100%' }} align="center">
          <div style={{justifyContent:"center",alignContent:"center"}}>
            <div style={{ marginBottom:10, alignContent:"center"}}>
              HIGH-SCORE: {this.state.highScore}
              &ensp;&ensp;&ensp;&ensp;SCORE:
              {' '}
              {this.state.score}
            </div>
            <div style={{justifyContent:"center", alignItems:"center" }}>
              <div style={{ marginBottom:10}}>
                <Button onClick={() => this.goUp()} style={{backgroundColor: "#56B19E", color:'white', width:50, height:50}}>
                  <KeyboardArrowUp />
                </Button>
              </div>
              <div style={{ marginBottom:10 }}>
                <Button onClick={() => this.goLeft()} style={{backgroundColor: "#56B19E", marginRight:40, color:'white', width:50, height:50}}>
                  <KeyboardArrowLeft />
                </Button>
                <Button onClick={() => this.goRight()} style={{backgroundColor: "#56B19E", marginLeft:40, color:'white', width:50, height:50}}>
                  <KeyboardArrowRight />
                </Button>
              </div>
              <div style={{ marginBottom:10}}>
                <Button onClick={() => this.goDown()} style={{backgroundColor: "#56B19E", color:'white',width:50, height:50}}>
                  <KeyboardArrowDown />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SnakeGame;
