import React from 'react';
import Button from '@mui/material/Button';

export interface ButtonIconProps {
  color?:'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined,
  label:string,
  variant?:'text' | 'outlined' | 'contained' | undefined,
  disabled?:boolean,
  className?:string,
  fullWidth?:boolean,
  size?:'small' | 'medium' | 'large' | undefined,
  onClick: () => void,
  icon?: React.ReactNode
}

export function LeftIconButton({
  color, label, variant, disabled, className, fullWidth, size, onClick, icon,
}: ButtonIconProps) {
  return (
    <Button
      className={className}
      color={color}
      size={size}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={icon}
    >
      {label}
    </Button>
  );
}

export function RightIconButton({
  color, label, variant, disabled, className, fullWidth, size, onClick, icon,
}: ButtonIconProps) {
  return (
    <Button
      className={className}
      color={color}
      size={size}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      endIcon={icon}
    >
      {label}
    </Button>
  );
}

export default LeftIconButton;
