import React from 'react';
import { Route } from 'react-router-dom';
import { RestaurantLayout } from '../../components/layout';
import RestaurantByLink from '../../pages/restaurantByLink';
import Restaurants from '../../pages/restaurants';
import SubsidiaryView from '../../pages/subsidiary/index';

const RestaurantRoutes = (
  <Route path="restaurants" element={<RestaurantLayout />}>
    <Route index element={<Restaurants />} />
    <Route path=":restaurant_name" element={<RestaurantByLink />} />
    <Route path=":restaurant_name/:subsidiary_name" element={<SubsidiaryView />} />
  </Route>
);

export default RestaurantRoutes;
