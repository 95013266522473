import React from 'react';
import { Outlet } from 'react-router-dom';
import TopNavBar from '../navbar/TopNavBar';
import Footer from '../footer';

export function RestaurantLayout() {
  return (
    <>
      <TopNavBar />
      <Outlet />
      <Footer />
    </>
  );
}
