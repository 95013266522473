import {
  Button, Link, Typography, useMediaQuery,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import env from '../../env';

const CustomizedButton = styled(Button)`
  text-align: center;
  padding-inline: 30px;
  padding-block: 10px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: none;
  @media (max-width: 450px) {
    padding-inline: 20px;
    padding-block: 6px;
  }
`;
function FooterWelcome() {
  const isMobile = useMediaQuery('(max-width:450px)');
  return (
    <footer className="footer">
      <div className="wrap">
        <div className="section-content">
          <Typography
            fontSize={29}
            variant="h3"
            color="white"
            textTransform="uppercase"
          >
            ¡Regístrate, vende y crece con nosotros!
          </Typography>
          <Typography
            fontWeight="normal"
            pt={3}
            variant="h2"
            color="white"
          >
            Regístrate en cuestión en minutos y recibe pedidos más rápido de
            lo que te imaginas.
          </Typography>
          <CustomizedButton
            style={{ marginTop: '3%' }}
            color="primary"
            size="medium"
            variant="contained"
            fullWidth={false}
            href={`${env.merchantUrl}/home`}
            onClick={() => {}}
          >
            ¡Inscribe tu comercio aquí!
          </CustomizedButton>
        </div>
      </div>

      <div className="black-circle">
        <img
          src="./assets/img/logo-white.png"
          alt="logo-xpress"
          width="auto"
          height={isMobile ? '100px' : '130px'}
        />

        <div className="footer-link">
          <Link href="/policy-and-privacy">
            <Typography
              fontWeight="normal"
              p={2}
              variant="h3"
              color="white"
            >
              Políticas de privacidad
            </Typography>
          </Link>
          <Link href="/terms-and-conditions">
            <Typography
              fontWeight="normal"
              variant="h3"
              color="white"
            >
              Términos & condiciones
            </Typography>
          </Link>
        </div>

        <div className="footer-copyright">
          © 2020 Xpress Technologies. All rights reserved.
          <div className="social-links">
            <a
              href="https://www.instagram.com/xpressbo"
              className="btn btn-primary"
            >
              <img
                className="social-icon"
                src="./assets/img/instagram.png"
                alt="Instagram Logo"
                width="auto"
                height="16px"
              />
            </a>
            <a
              href="https://www.facebook.com/xpressbo"
              className="btn btn-primary"
            >
              <img
                className="social-icon"
                src="./assets/img/facebook.png"
                alt="Facebook Logo"
                width="auto"
                height="16px"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterWelcome;
