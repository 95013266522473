// eslint-disable-next-line max-classes-per-file

import DeliveryInformation from './deliveryInformation';
import ScheduleDetails from './scheduleDetails';
import { ORDER_TYPE, Subsidiary } from '../../../dto';

export default class SubsidiaryModel extends Subsidiary {
  approximateDistance: number;

  scheduleDetails: ScheduleDetails;

  deliveryInformation: DeliveryInformation;

  factor: number;

  order_services: ORDER_TYPE[];

  emit_invoice: boolean;

  popular_products: any[];

  deliveryFree: { enable: boolean; minimumAmount: number; chargeByXpress: boolean };

  constructor({
    approximateDistance = 0,
    scheduleDetails = new ScheduleDetails({}),
    deliveryInformation = new DeliveryInformation({}),
    factor = 0,
    order_services = [ORDER_TYPE.PICK_UP],
    emit_invoice = true,
    popular_products = [],
    deliveryFree = {
      enable: false,
      minimumAmount: 0,
      chargeByXpress: false,
    },
    ...data
  }) {
    super(data);
    this.approximateDistance = approximateDistance;
    this.scheduleDetails = new ScheduleDetails(scheduleDetails);
    this.deliveryInformation = new DeliveryInformation(deliveryInformation);
    this.factor = factor;
    this.order_services = order_services;
    this.emit_invoice = emit_invoice;
    this.popular_products = popular_products;
    this.deliveryFree = deliveryFree;
  }
}
