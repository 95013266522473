import { createSlice } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { Category } from '../../../dto';
import { Nullable } from '../../../types';

type State = { allCategories: Nullable<Category[]> }

const initialState: State = { allCategories: null };

export const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setAllCategories: (state, action) => {
      state.allCategories = action.payload;
    },
  },
});

export const { setAllCategories } = categorySlice.actions;
export default categorySlice.reducer;

export const categoriesReducerTransform = createTransform(
  (toSave:State) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
    allCategories:
      toRehydrated.allCategories
        ? toRehydrated.allCategories.map((item) => new Category(item))
        : null,
  }),
  { whitelist: ['categories'] },
);
