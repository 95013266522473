// eslint-disable-next-line max-classes-per-file
import { GeoPoint, Timestamp } from 'firebase/firestore';
import { RANGE_PRICE, REGISTER_STATUS } from '../enums';
import FirebaseBase, { Base } from '../firebaseBase';
import City from '../city';
import MenuSection from '../menuSection';
import { toGeoPoint, toTimestamp } from '../../utils/firestoreUtils';
import { Nullable } from '../../types';

export class ScheduleDayItem extends Base {
  start_time: Nullable<Timestamp>;

  end_time: Nullable<Timestamp>;

  constructor({ start_time = null, end_time = null }) {
    super();
    this.start_time = start_time ? toTimestamp(start_time) : null;
    this.end_time = end_time ? toTimestamp(end_time) : null;
  }
}
export class ScheduleDay extends Base {
  journey: string;

  open: boolean;

  schedule: ScheduleDayItem[];

  constructor({ journey = '', open = false, schedule = [] }) {
    super();
    this.journey = journey;
    this.open = open;
    this.schedule = schedule.map((item) => new ScheduleDayItem(item));
  }
}
export class Subsidiary extends FirebaseBase {
  restaurant_id: string;

  city: City;

  address_2: string;

  name: string;

  address: string;

  location: GeoPoint;

  geohash: string;

  phone_number: string;

  range_price: RANGE_PRICE;

  average_price: number;

  delivery_time: number;

  categories: any[];

  main_category: string;

  approvalsPercentage: number;

  orders_number: number;

  menu_section: MenuSection[];

  schedule: ScheduleDay[];

  likes: number;

  disapprovals: number;

  approvals: number;

  legal_representant: string;

  nit: string;

  restaurant_name: string;

  banner_photo: any;

  logo_photo: any;

  receiving_orders: boolean;

  price_area_id: string;

  register_status: REGISTER_STATUS;

  cooked_average: any;

  normalized_name: string;

  normalized_restaurant_name:string;

  constructor({
    restaurant_id = '',
    city = {},
    name = '',
    address = '',
    address_2 = '',
    location = null,
    geohash = '',
    phone_number = '',
    range_price = RANGE_PRICE.VERY_EXPENSIVE,
    average_price = 0,
    delivery_time = 0,
    categories = [],
    main_category = '',
    approvalsPercentage = 0,
    orders_number = 0,
    menu_section = [],
    schedule = [],
    likes = 0,
    disapprovals = 0,
    approvals = 0,
    legal_representant = '',
    nit = '',
    restaurant_name = '',
    banner_photo = null,
    logo_photo = null,
    receiving_orders = true,
    price_area_id = '',
    register_status = REGISTER_STATUS.PENDING,
    cooked_average = {},
    normalized_name = '',
    normalized_restaurant_name = '',
    ...data
  } = {}) {
    super(data);
    this.restaurant_id = restaurant_id;
    this.city = new City(city);
    this.name = name;
    this.address = address;
    this.address_2 = address_2;
    this.location = toGeoPoint(location);
    this.geohash = geohash;
    this.phone_number = phone_number;
    this.range_price = range_price;
    this.average_price = average_price;
    this.delivery_time = delivery_time;
    this.categories = categories;
    this.main_category = main_category;
    this.approvalsPercentage = approvalsPercentage;
    this.orders_number = orders_number;
    this.menu_section = menu_section.map((section) => new MenuSection(section));
    this.schedule = schedule.map((item) => new ScheduleDay(item));
    this.likes = likes;
    this.disapprovals = disapprovals;
    this.approvals = approvals;
    this.legal_representant = legal_representant;
    this.nit = nit;
    this.restaurant_name = restaurant_name;
    this.banner_photo = banner_photo;
    this.logo_photo = logo_photo;
    this.receiving_orders = receiving_orders;
    this.price_area_id = price_area_id;
    this.register_status = register_status;
    this.cooked_average = cooked_average;
    this.normalized_name = normalized_name;
    this.normalized_restaurant_name = normalized_restaurant_name;
  }
}
