import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, styled,
} from '@mui/material';
import { Category, OldPage } from '../../components';
import { RestaurantCard } from '../../components/card/restaurantCard';
import { ProductCard } from '../../components/card/productCard';
import { useAppSelector } from '../../hooks/redux';
import SimpleCarrousel from '../../components/carrousel/simpleCarrousel';
import Product from '../../dto/product';
import SubsidiaryModel from '../../models/home/models/subsidiaryModel';
import InstallersModal from '../../components/modals/InstallersModal';
import { AddressModal } from '../../components/modals';
import { ORDER_TYPE } from '../../dto';

const BoxCustom = styled(Box)(() => ({
  background: 'rgba(255,255,255,0.9)',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 100000,
}));

function filterDishes(dishes, subsidiaries, filter) {
  let result:any[] = [];
  if (filter !== '') {
    if (dishes) {
      dishes.forEach((product) => {
        const subsidiary = subsidiaries?.find((item) => item.id === product.subsidiary_id);
        const aux:Record<string, any> = {
          restaurant_name: subsidiary.restaurant_name,
          product_name: product.name,
        };
        const json = JSON.stringify(aux).toLowerCase();
        const conditionFilter = filter !== '' ? json.includes(filter.toLowerCase()) : false;
        if (conditionFilter) {
          result.push(product);
        }
      });
    }
  } else {
    result = dishes;
  }
  return result;
}

function filterSubsidiaries(subsidiaries, filter, categoryId) {
  const result:any[] = [];
  if (subsidiaries) {
    subsidiaries.forEach((subsidiary) => {
      const aux:Record<string, any> = {
        restaurant_name: subsidiary.restaurant_name,
        subsidiary_name: subsidiary.name,
        categories: subsidiary.categories.join(' '),
      };
      const json = JSON.stringify(aux).toLowerCase();
      const conditionFilter = filter !== '' ? json.includes(filter.toLowerCase()) : false;
      const conditionCategory = categoryId !== ''
        ? json.includes(categoryId.toLowerCase())
        : false;
      if (conditionFilter || conditionCategory) {
        result.push(subsidiary);
      }
    });
  }
  return result;
}

type DishItemProps = {
  product:Product,
  subsidiaries:SubsidiaryModel[]
}
function DishItem({ product, subsidiaries }:DishItemProps) {
  const [openModal, setOpenModal] = useState(false);
  const subsidiary = useMemo(() => subsidiaries?.find(
    (item) => item.id === product.subsidiary_id,
  ), [product, subsidiaries]);
  if (!subsidiary || !product) {
    return null;
  }
  return (
    <>
      <ProductCard
        src={product.photo.x400 || product.photo.original}
        alt=""
        restaurantName={subsidiary.restaurant_name}
        productName={product.name}
        handleClick={(() => setOpenModal(true))}
      />
      <InstallersModal open={openModal} onClose={(() => setOpenModal(false))} />
    </>
  );
}

function Restaurants() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:450px)');
  const responsiveSpacing = isMobile ? 3 : 6;
  const isLoading = useAppSelector((store) => store.loaders.isLoading);
  const homeData = useAppSelector((store) => store.home.data);
  const categories = useAppSelector((store) => store.categories.allCategories);
  const requestedInArea = useAppSelector((store) => store.home.data?.requestedInArea);
  const topRated = useAppSelector((store) => store.home.data?.topRated);
  const requestedDishInArea = useAppSelector((store) => store.home.data?.requestedDishInArea);
  const subsidiaries = useAppSelector((store) => store.home.data?.subsidiaries);
  const filter = useAppSelector((store) => store.navBar.searchNavbar);
  const [openModalAddress, setOpenModalAddress] = useState(false);
  const [categorySelectedId, setCategorySelectedId] = useState<string>(
    '',
  );

  const [
    filteredReqInAreaSubs,
    filteredTopRatedSubs,
    filteredReqDishInAreaProd,
    filteredSubsidiaries,
  ] = useMemo(() => {
    const requestedInAreaDelivery = requestedInArea?.filter(
      (sub) => sub.order_services.includes(ORDER_TYPE.DELIVERY),
    );
    const topRatedDelivery = topRated?.filter(
      (sub) => sub.order_services.includes(ORDER_TYPE.DELIVERY),
    );
    const subsidiariesDelivery = subsidiaries?.filter(
      (sub) => sub.order_services.includes(ORDER_TYPE.DELIVERY),
    );
    if (subsidiaries && categorySelectedId === '' && filter === '') {
      return [requestedInAreaDelivery, topRatedDelivery, requestedDishInArea, subsidiariesDelivery];
    }
    if (subsidiaries) {
      return [
        filterSubsidiaries(requestedInAreaDelivery, filter, categorySelectedId),
        filterSubsidiaries(topRatedDelivery, filter, categorySelectedId),
        filterDishes(requestedDishInArea, subsidiaries, filter),
        filterSubsidiaries(subsidiariesDelivery, filter, categorySelectedId),
      ];
    }
    return [null, null, null, null];
  }, [
    requestedInArea,
    topRated,
    requestedDishInArea,
    subsidiaries,
    filter,
    categorySelectedId,
  ]);

  const handleCategorySelected = (newCategorySelectedId:string) => {
    if (newCategorySelectedId === categorySelectedId) {
      setCategorySelectedId('');
    } else {
      setCategorySelectedId(newCategorySelectedId);
    }
  };

  useEffect(() => {
    if (homeData?.subsidiaries.length === 0) {
      setOpenModalAddress(true);
    }
  }, []);

  const handleClikRestaurant = (normalizedName, normalizedRestaurantName) => {
    navigate(`../${normalizedRestaurantName}/${normalizedName}`, { replace: false });
  };

  return (
    <OldPage title="Restaurantes">
      <Box sx={{ position: 'relative' }}>
        {isLoading && (
          <BoxCustom>
            <Box mt={10}>
              <CircularProgress sx={{ color: '#56B19E' }} />
            </Box>
          </BoxCustom>
        )}
        <Stack
          mt={isMobile ? 1 : 3}
          pr={responsiveSpacing}
          pl={responsiveSpacing}
        >
          <Typography variant={isMobile ? 'body2' : 'h2'} sx={{ color: '#56B19E' }}>
            <strong>
              Categorias
            </strong>
          </Typography>
          {!categories && (
            <Stack justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          )}
          {categories && (
            <Grid mt={isMobile ? 2 : 4}>
              <SimpleCarrousel xs={3} sm={4} md={6} lg={8} xl={10}>
                {categories && categories.map((category) => (
                  <Grid key={category.id}>
                    <Category
                      id={category.id}
                      src={category.img.x400}
                      label={category.name}
                      isSelected={categorySelectedId === category.id}
                      handleClick={
                        handleCategorySelected
                      }
                    />
                  </Grid>
                ))}
              </SimpleCarrousel>
            </Grid>
          )}
        </Stack>
        {filteredReqInAreaSubs && filteredReqInAreaSubs.length > 0 && (
          <Stack
            bgcolor="#FEFCFB99"
            mt={isMobile ? 2 : 4}
            pr={responsiveSpacing}
            pl={responsiveSpacing}
          >
            <Typography variant={isMobile ? 'body2' : 'h2'} sx={{ color: '#56B19E' }}>
              <strong>
                Lo que están pidiendo en tu área
              </strong>
            </Typography>
            <Grid mt={isMobile ? 0 : 2}>
              <SimpleCarrousel xs={1} sm={2} md={3} lg={4} xl={4}>
                {filteredReqInAreaSubs.map((subsidiary) => (
                  <Grid key={subsidiary.id} spacing={3} m={2}>
                    <RestaurantCard
                      src={subsidiary.banner_photo.x400 || subsidiary.banner_photo.original}
                      restaurantName={subsidiary.restaurant_name}
                      subsidiaryName={subsidiary.name}
                      categories={subsidiary.categories}
                      likes={subsidiary.likes || 0}
                      approvalsPercentage={subsidiary.approvalsPercentage || 0}
                      price={subsidiary.deliveryInformation.price}
                      distance={subsidiary.deliveryInformation.distance}
                      handleClik={() => {
                        const subName: string = subsidiary.normalized_name;
                        const restName: string = subsidiary.normalized_restaurant_name;
                        handleClikRestaurant(subName, restName);
                      }}
                    />
                  </Grid>
                ))}
              </SimpleCarrousel>
            </Grid>
          </Stack>
        )}
        {filteredTopRatedSubs && filteredTopRatedSubs.length > 0 && (
          <Stack
            mt={isMobile ? 2 : 4}
            pr={responsiveSpacing}
            pl={responsiveSpacing}
          >
            <Typography variant={isMobile ? 'body2' : 'h2'} sx={{ color: '#56B19E' }}>
              <strong>
                Comercios con mejor calificación
              </strong>
            </Typography>
            <Grid mt={isMobile ? 0 : 2}>
              <SimpleCarrousel xs={1} sm={2} md={3} lg={4} xl={4}>
                {filteredTopRatedSubs && filteredTopRatedSubs.map((subsidiary) => (
                  <Grid spacing={3} m={2} key={subsidiary.id}>
                    <RestaurantCard
                      // eslint-disable-next-line max-len
                      src={subsidiary.banner_photo.x400 || subsidiary.banner_photo.original || './assets/img/placeholderBig.jpg'}
                      restaurantName={subsidiary.restaurant_name}
                      subsidiaryName={subsidiary.name}
                      categories={subsidiary.categories}
                      likes={subsidiary.likes || 0}
                      approvalsPercentage={subsidiary.approvalsPercentage || 0}
                      price={subsidiary.deliveryInformation.price}
                      distance={subsidiary.deliveryInformation.distance}
                      handleClik={() => {
                        const subName: string = subsidiary.normalized_name;
                        const restName: string = subsidiary.normalized_restaurant_name;
                        handleClikRestaurant(subName, restName);
                      }}
                    />
                  </Grid>
                ))}
              </SimpleCarrousel>
            </Grid>
          </Stack>
        )}
        {filteredReqDishInAreaProd && filteredReqDishInAreaProd.length > 0 && (
          <Stack
            bgcolor="#F8FCFB"
            mt={isMobile ? 2 : 4}
            pr={responsiveSpacing}
            pl={responsiveSpacing}
          >
            <Typography variant={isMobile ? 'body2' : 'h2'} sx={{ color: '#56B19E' }}>
              <strong>
                Los platos más pedidos en tu área
              </strong>
            </Typography>
            <Grid mt={isMobile ? 0 : 2}>
              <SimpleCarrousel xs={1} sm={3} md={5} lg={6} xl={7}>
                {filteredReqDishInAreaProd.map((product) => (
                  <Grid key={product.id} spacing={3} m={2}>
                    <DishItem
                      product={product}
                      subsidiaries={subsidiaries || []}
                    />
                  </Grid>
                ))}
              </SimpleCarrousel>
            </Grid>
          </Stack>
        )}
        {filteredSubsidiaries && filteredSubsidiaries.length > 0 && (
          <Stack
            mt={isMobile ? 2 : 4}
            pr={responsiveSpacing}
            pl={responsiveSpacing}
          >
            <Typography variant={isMobile ? 'body2' : 'h2'} sx={{ color: '#56B19E' }}>
              <strong>
                Lo más cercanos
              </strong>
            </Typography>
            <Grid
              container
              mt={isMobile ? 0 : 2}
              mb={3}
              rowSpacing={2}
              columnSpacing={{ xs: 2, md: 3, lg: 8 }}
            >
              {filteredSubsidiaries?.map((subsidiary) => (
                <Grid
                  item
                  key={subsidiary.id}
                  width={isMobile ? '90vw' : '100%'}
                  xs={isMobile ? 9 : 6}
                  sm={4}
                  md={3}
                >
                  <RestaurantCard
                    src={subsidiary.banner_photo.x400 || subsidiary.banner_photo.original}
                    restaurantName={subsidiary.restaurant_name}
                    subsidiaryName={subsidiary.name}
                    categories={subsidiary.categories}
                    likes={subsidiary.likes || 0}
                    approvalsPercentage={subsidiary.approvalsPercentage || 0}
                    price={subsidiary.deliveryInformation.price}
                    distance={subsidiary.deliveryInformation.distance}
                    handleClik={() => {
                      const subName: string = subsidiary.normalized_name;
                      const restName: string = subsidiary.normalized_restaurant_name;
                      handleClikRestaurant(subName, restName);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
      </Box>
      <AddressModal
        open={openModalAddress}
        onClose={() => {
          setOpenModalAddress(false);
        }}
      />
    </OldPage>
  );
}

export default Restaurants;
