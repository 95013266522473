import React from 'react';
import {
  Container,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

export type SelectOption = {
  id: string;
  name: string;
};

type ValuesTypes = {
  city: string | undefined;
  address: string | undefined;
};

type ErrorTypes = {
  city: string | null;
  address: string | null;
};

export type AddressInputProps = {
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectOptions: SelectOption[];
  // eslint-disable-next-line no-unused-vars
  onChangeSelect: (event: SelectChangeEvent) => void;
  onEnterPress:(event:React.KeyboardEvent<HTMLDivElement>)=>void;
  values: ValuesTypes;
  className?: string;
  errors?: ErrorTypes;
};

export function AddressInput({
  selectOptions,
  onChange,
  onEnterPress,
  values,
  onChangeSelect,
  className,
  errors,
}: AddressInputProps) {
  return (
    <Container maxWidth="md">
      <Grid
        className={className}
        container
        justifyContent="start"
        alignItems="center"
        sx={{ borderRadius: '50px', backgroundColor: 'white' }}
      >
        <Grid item xs={5} sm={3} md={4} borderRight={1} borderColor="#B2E7D8">
          <Select
            disableUnderline
            startAdornment={(
              <IconButton>
                <LocationOnOutlinedIcon />
              </IconButton>
            )}
            fullWidth
            name="city"
            value={values.city}
            onChange={onChangeSelect}
            variant="standard"
          >
            {selectOptions?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6} sm={8} md={7} marginLeft={1}>
          <InputBase
            color="primary"
            placeholder="Ingresa tu dirección"
            fullWidth
            name="address"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onEnterPress(e);
              }
            }}
            value={values.address}
            onChange={onChange}
          />
          {Boolean(errors?.address) && (
            <Typography variant="body2" sx={{ color: 'red' }}>
              {Boolean(errors?.address) && errors?.address}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default AddressInput;
