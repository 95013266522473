import React from 'react';
import { styled } from '@mui/material/styles';
import {
  TextField, InputBase, FormControl, InputLabel, FormHelperText,
} from '@mui/material';

const CustomizedTextField = styled(TextField)({
  boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
  borderRadius: '20px',
  backgroundColor: 'white',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '20px',
    },
  },
});

const SimpleInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    borderRadius: 20,
    position: 'relative',
    boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'white',
    padding: '15px 19px',
  },
}));

export interface TextFieldWithIconProps {
  id: string,
  name: string,
  type?: string,
  color?:'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined,
  disabled?: boolean,
  error?:boolean,
  fullWidth?:boolean,
  focused?:boolean,
  helperText?:React.ReactNode,
  label?:string,
  // eslint-disable-next-line no-unused-vars
  onChange?:(event: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyPress?:(event:React.KeyboardEvent<HTMLDivElement>)=>void;
  placeholder?:string,
  required?:boolean,
  value?:string,
  variant?:'filled'
  | 'outlined'
  | 'standard',
}

export function RoundedTextField({
  id,
  name,
  type,
  color,
  disabled,
  error,
  fullWidth,
  helperText,
  label,
  onChange,
  placeholder,
  onKeyPress,
  required,
  value,
  variant,
  focused,
}: TextFieldWithIconProps) {
  return (
    <CustomizedTextField
      id={id}
      name={name}
      type={type}
      color={color}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      focused={focused}
      helperText={helperText}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      value={value}
      variant={variant}
      onKeyPress={onKeyPress}
    />
  );
}

export function RoundedSimpleTextField({
  id,
  name,
  type,
  color,
  disabled,
  error,
  fullWidth,
  helperText,
  label,
  onChange,
  placeholder,
  onKeyPress,
  required,
  value,
  variant,
  focused,
}: TextFieldWithIconProps) {
  return (
    <FormControl variant={variant} focused={focused}>
      <InputLabel shrink htmlFor={id} style={{ color: 'white', fontWeight: 'bold' }}>
        {label}
      </InputLabel>
      <SimpleInput
        id={id}
        name={name}
        type={type}
        color={color}
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        onChange={onChange}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        required={required}
        value={value}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default RoundedTextField;
