import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { createFirestoreInstance } from 'redux-firestore';
import rootReducers from '../reducers';
import persistConfig from './persistConfig';
import { rrfConfig } from '../redux-firebase';

export const persistedReducer = persistReducer(
  persistConfig,
  rootReducers,
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const admin = firebase;
export default store;
