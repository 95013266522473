import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HeaderWelcome from './headerWelcome';
import FooterWelcome from './footerWelcome';
import ContentWelcome from './contentWelcome';

function HomeView() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <HeaderWelcome />
      <ContentWelcome />
      <FooterWelcome />
    </div>
  );
}
export default HomeView;
