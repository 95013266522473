/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const LINK_APPS = {
  android: 'https://play.google.com/store/apps/details?id=com.believesol.xpress',
  ios: 'https://apps.apple.com/bo/app/xpress/id1507805959',
};

export const COLLECTIONS = {
  USER: 'users',
  SUBSIDIARY: 'subsidiary',
  PRODUCT: 'product',
  CATEGORIES: 'categories',
  MENU_ADDITIONS: 'menu_additions',
  ADDITION: 'additions',
  RESTAURANT: 'restaurants',
  CITY: 'city',
  RESTAURANTS_SUMMARY: 'restaurants_summary',
  SETTINGS: 'settings',
};
export const TIME_ZONES = {
  BOLIVIA: -240,
};

export const COOK_AVERAGE_TYPE = [
  {
    type: 'VERY_FAST',
    min: 0,
    max: 10,
  },
  {
    type: 'FAST',
    min: 11,
    max: 20,
  },
  {
    type: 'NORMAL',
    min: 21,
    max: 30,
  },
  {
    type: 'SLOW',
    min: 31,
    max: 40,
  },
  {
    type: 'VERY_SLOW',
    min: 41,
    max: 50,
  },
];

export const DAYS = [
  { en_us: 'MONDAY', value: 'Lunes' },
  { en_us: 'TUESDAY', value: 'Martes' },
  { en_us: 'WEDNESDAY', value: 'Miercoles' },
  { en_us: 'THURSDAY', value: 'Jueves' },
  { en_us: 'FRIDAY', value: 'Viernes' },
  { en_us: 'SATURDAY', value: 'Sabado' },
  { en_us: 'SUNDAY', value: 'Domingo' },
];
export const FUNCTIONS_URLS = {
  GET_VERIFICATION_CODE: '/twilio/getCode/',
  VERIFY_CODE: '/twilio/verifyCode/',
  DELETE_USER: '/users/deleteUserAccount',
};

export const BACKEND_URLS = {
  VERIFY_PHONE: '/Auth/verifyPhoneNumber/',
};
