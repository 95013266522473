/* eslint-disable */
import React, { Component } from 'react';
import Carta from './Carta';
import './Tablero.css';
import {Grid} from "@mui/material";

export default class Tablero extends Component {
  render() {
    return (
      <Grid container spacing={1} style={{padding:8}} >
        {
          this.props.baraja
            .map((carta, index) => {
              const estaSiendoComparada = this.props.parejaSeleccionada.indexOf(carta) > -1;
              return (
                <Carta
                  key={index}
                  icono={carta.icono}
                  estaSiendoComparada={estaSiendoComparada}
                  seleccionarCarta={() => this.props.seleccionarCarta(carta)}
                  fueAdivinada={carta.fueAdivinada}
                />
              );
            })
        }
      </Grid>
    );
  }
}
