import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import DownloadApps from '../../components/downloadApps/DownloadApps';

function Invite() {
  const { inviteId } = useParams();
  if (inviteId) {
    return (
      <Navigate to={{
        pathname: '/installers',
        search: `xpress://invite/${inviteId}`,
      }}
      />
    );
  }

  return (<DownloadApps />);
}
export default Invite;
