import { Environment } from './type';

const TestEnv: Environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyCmv7FVJPlXC6OVviiSkZtiOGt6I6MqoL4',
    authDomain: 'xpress-test-env.firebaseapp.com',
    databaseURL: 'https://xpress-test-env.firebaseio.com',
    projectId: 'xpress-test-env',
    storageBucket: 'xpress-test-env.appspot.com',
    messagingSenderId: '67978209754',
    appId: '1:67978209754:web:ac56cd7e750e5faad7f8e3',
    measurementId: 'G-2XLZQBZ3YK',
  },
  cloudFunctionsUrl: 'https://us-central1-xpress-test-env.cloudfunctions.net',
  xpressWebJsUrl: 'https://xpress-test-web-js.firebaseapp.com',
  companyName: 'XPRESS Technologies',
  merchantUrl: 'https://xpress-test-admin.web.app',
  adminUrl: 'https://xpress-dev.web.app',
  googleAnalytics: 'UA-236246807-4',
  backendUrl: 'https://xpress-api-ucccw7ir7q-uc.a.run.app',
};

export default TestEnv;
