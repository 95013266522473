import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { Address } from '../../../dto';
import { Nullable } from '../../../types';

interface State {
  selectedAddress:Nullable<Address>
}

const initialState: State = {
  selectedAddress: null,
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setCurrentAddress: (state, action:PayloadAction<Address>) => {
      state.selectedAddress = action.payload;
    },
  },
});

export const { setCurrentAddress } = addressSlice.actions;
export default addressSlice.reducer;
export const addressReducerTransform = createTransform(
  (toSave:State) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
    selectedAddress: Address.fromJSON(toRehydrated.selectedAddress),
  }),
  { whitelist: ['address'] },
);
