import { Route } from 'react-router-dom';
import React from 'react';
import InviteView from '../../pages/invite';

const TopicsRoutes = (
  <Route path="topics">
    <Route path=":inviteId" element={<InviteView />} />
  </Route>
);

export default TopicsRoutes;
