import React, { useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Typography, Stack, useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import { ImageButton, RoundedWideButton } from '../../components/button';
import { AddressInput } from '../../components/inputs';
import { RoundedSimpleTextField } from '../../components/textfield';
import { SelectSimpleRounded } from '../../components/select';
import { MapAddressModal } from './mapAddressModal';
import { useAppSelector } from '../../hooks/redux';
import Images from '../../assets/img/images';
import { ROUTES } from '../../routes/constantsRoutes';

const Header = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${Images.hero})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'right top',
  height: '100vh',
  paddingBlock: '10px',
  paddingInline: '35px',
  [theme.breakpoints.down('xs')]: {
    paddingInline: '16px',
    paddingBlock: '20px',
  },
}));

const TopNavBar = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SearchContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  gap: 80px;
`;

const getInitialValues = (city) => ({
  city: city?.id || '',
  address: '',
});

const initialErrors = {
  city: null,
  address: null,
};

type ValuesTypes = {
  city: string | null;
  address: string | null;
};

function Hero() {
  const navigate = useNavigate();
  const cities = useAppSelector((store) => store.cities.allCities);
  const defaultCity = cities?.find((c) => c.is_default === true);
  const [openModal, setOpenModal] = useState(false);
  const [errors, setErrors] = useState<ValuesTypes>(initialErrors);
  const [searchData, setSearchData] = useState(getInitialValues(defaultCity));
  const isMobile = useMediaQuery('(max-width:450px)');
  const findCity = useMemo(() => {
    const cityId = searchData.city;
    return cities?.find((city) => city.id === cityId);
  }, [searchData.city]);

  useEffect(() => {
    if (defaultCity) {
      setSearchData(getInitialValues(defaultCity));
    }
  }, [defaultCity]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };
  const handleSelect = (event: SelectChangeEvent): void => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleClick = () => {
    if (searchData?.city.trim() === '') {
      setErrors({ ...errors, city: 'Se debe seleccionar una ciudad' });
      return;
    }
    if (searchData?.address.trim() === '') {
      setErrors({ ...errors, address: 'Es un parametro requerido' });
      return;
    }
    setErrors(initialErrors);
    setOpenModal(true);
  };

  return (
    <Header>
      <MapAddressModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        address={searchData.address}
        city={findCity}
      />
      <TopNavBar>
        <ImageButton
          src="./assets/img/logo-white.png"
          alt="logo-xpress"
          href={ROUTES.WELCOME}
          width="auto"
          height={isMobile ? '40px' : '60px'}
        />
        <RoundedWideButton
          color="primary"
          label="Comercios"
          variant="contained"
          disabled={false}
          className=""
          fullWidth={false}
          onClick={() => {
            navigate('../commerce', { replace: false });
          }}
        />
      </TopNavBar>
      <SearchContainer>
        {!isMobile && (
          <Typography variant="h1" style={{ color: 'white' }}>
            Pide comida a domicilio con Xpress ahora!
          </Typography>
        )}
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 12 : 0.5}
          maxWidth={isMobile ? '268px' : '800px'}
          width="100%"
        >
          {!isMobile && cities && (
            <AddressInput
              onChange={handleChange}
              onEnterPress={handleClick}
              onChangeSelect={handleSelect}
              selectOptions={cities}
              values={searchData}
              errors={errors}
            />
          )}
          {isMobile && cities && (
            <>
              <SelectSimpleRounded
                id="select-city"
                name="city"
                error={Boolean(errors.city)}
                fullWidth
                helperText={Boolean(errors.city) && errors.city}
                label="Ciudad"
                onChange={handleSelect}
                value={searchData.city}
                selectList={cities}
              />
              <RoundedSimpleTextField
                id="search-address"
                name="address"
                type="text"
                color="primary"
                focused
                error={Boolean(errors.address)}
                fullWidth
                helperText={Boolean(errors.address) && errors.address}
                label="Ingrese su dirección"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleClick();
                  }
                }}
                onChange={handleChange}
                placeholder="Ingrese su dirección"
                value={searchData?.address}
                variant="outlined"
              />
            </>
          )}
          <Box textAlign="center">
            <RoundedWideButton
              label="Buscar"
              onClick={handleClick}
              variant="contained"
              fullWidth={false}
            />
          </Box>
        </Stack>
      </SearchContainer>
    </Header>
  );
}

export default Hero;
