import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { MobileServices } from '../../services';
import { setHomeData } from '../../redux/reducers/home';
import { setLoaderListRestaurants } from '../../redux/reducers/loader';

type HomeProviderProps = {
  children: any
}

const useHomeProvider = () => {
  const dispatch = useAppDispatch();
  const selectedAddress = useAppSelector((state) => state.address.selectedAddress);
  useEffect(() => {
    if (selectedAddress?.location) {
      dispatch(setLoaderListRestaurants(true));
      MobileServices.getHomeData({
        location: selectedAddress.location.toJSON(),
      }).then((homeData) => {
        dispatch(setHomeData(homeData));
        dispatch(setLoaderListRestaurants(false));
      }).catch((error) => {
        dispatch(setLoaderListRestaurants(false));
        console.error(error);
      });
    }
  }, [selectedAddress]);
};
export function HomeProvider({ children }:HomeProviderProps) {
  useHomeProvider();
  return children;
}
