import { GeoPoint } from 'firebase/firestore';
import { Base } from '../firebaseBase';
import { Constructor, Nullable } from '../../types';
import { TYPE_HOME } from '../enums';

export class Address extends Base {
  address: string;

  address_reference: string;

  geohash: string;

  id: string;

  isXpressMotos: boolean;

  location: Nullable<GeoPoint>;

  name: string;

  status: boolean;

  type_home: TYPE_HOME;

  constructor({
    address = '',
    address_reference = '',
    geohash = '',
    id = '',
    isXpressMotos = false,
    location = null,
    name = '',
    status = true,
    type_home = TYPE_HOME.HOUSE,

  }:Constructor<Address>) {
    super();
    this.address = address;
    this.address_reference = address_reference;
    this.geohash = geohash;
    this.id = id;
    this.isXpressMotos = isXpressMotos;
    this.location = location;
    this.name = name;
    this.status = status;
    this.type_home = type_home;
  }

  static fromJSON(json:any) {
    return new Address({
      ...json,
      location: new GeoPoint(json.location.latitude, json.location.longitude),
    });
  }
}
