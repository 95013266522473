import { Timestamp } from 'firebase/firestore';
import { Constructor, Nullable } from '../../../types';
import { toTimestamp } from '../../../utils/firestoreUtils';

export default class ScheduleDetails {
  isOpened: boolean;

  openHour: Nullable<Timestamp>;

  constructor({ isOpened = false, openHour = null }:Constructor<ScheduleDetails>) {
    this.isOpened = isOpened;
    this.openHour = openHour ? toTimestamp(openHour) : null;
  }
}
