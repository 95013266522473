import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Stack, Typography,
} from '@mui/material';
import Images from '../../assets/img/images';

const CardContainer = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
`;

export interface ProductCardProps {
  src?:string,
  alt?:string,
  restaurantName: string,
  productName: string,
  handleClick: () => void,
}

export function ProductCard({
  src, alt, restaurantName = '', productName = '', handleClick = () => {},
}:ProductCardProps) {
  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = Images.placeholderSmall;
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.className = 'error';
  };

  return (
    <CardContainer onClick={handleClick}>
      <img
        style={{
          height: '140px',
          width: '100%',
          boxShadow: '0px 4.71186px 9.42373px rgba(0, 0, 0, 0.25)',
          borderRadius: '11.7797px',
        }}
        src={src}
        alt={alt}
        onError={imageOnErrorHandler}
      />
      <Stack spacing={0.5}>
        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>{productName}</Typography>
        <Typography sx={{ fontSize: 12 }}>{restaurantName}</Typography>
      </Stack>
    </CardContainer>
  );
}

export default ProductCard;
