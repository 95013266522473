import React from 'react';
import IconButton from '@mui/material/IconButton';

export interface OnlyButtonIconProps {
  color?:'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined,
  disabled?:boolean,
  className?:string,
  size?:'small' | 'medium' | 'large' | undefined,
  onClick: () => void,
  children?: React.ReactNode
}

export function OnlyIconButton({
  color,
  disabled,
  className,
  size,
  onClick,
  children,
}: OnlyButtonIconProps) {
  return (
    <IconButton
      color={color}
      disabled={disabled}
      className={className}
      size={size}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
}

export default OnlyIconButton;
