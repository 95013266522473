export enum TYPE_HOME {
  HOUSE = 'HOUSE',
  WORK = 'WORK',
}
export enum ORDER_TYPE {
  PICK_UP= 'PICK_UP',
  DELIVERY= 'DELIVERY'
}

export enum REGISTER_STATUS {
  PENDING= 'PENDING',
  REJECTED= 'REJECTED',
  ACTIVE= 'ACTIVE',
  ARCHIVE= 'ARCHIVE',
  SUSPENDED= 'SUSPENDED'
}

export enum RANGE_PRICE {
  CHEAPER= 'CHEAPER',
  APPROACHABLE= 'APPROACHABLE',
  EXPENSIVE= 'EXPENSIVE',
  VERY_EXPENSIVE= 'VERY_EXPENSIVE'
}
