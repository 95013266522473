// eslint-disable-next-line
var Data = {
  palabras: [
    'Laptop',
    'Televisor',
    'Nevera',
    'Piedra',
    'Papel',
    'Tijeras',
    'Llama',
    'Perro',
    'Campana',
    'Silla',
    'Tortuga',
    'Canoa',
    'Ventana',
    'Camioneta',
  ],
};

export default Data;
