import { useEffect } from 'react';
import { admin } from '../../redux/store';
import { COLLECTIONS } from '../../constants';
import { Category } from '../../dto';
import { useAppDispatch } from '../../hooks/redux';
import { setAllCategories } from '../../redux/reducers/category';

const useCategoriesProvider = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    admin
      .firestore()
      .collection(COLLECTIONS.CATEGORIES)
      .where('enable', '==', true)
      .onSnapshot((querySnapshot) => {
        const categories: Category[] = [];
        querySnapshot.forEach((doc) => {
          const newCategory = new Category({ ...doc.data(), id: doc.id });
          categories.push(newCategory);
        });
        dispatch(setAllCategories(categories));
      });
  }, []);
};
type CategoriesProviderProps = {
  children: any
}
export function CategoriesProvider({ children }:CategoriesProviderProps) {
  useCategoriesProvider();
  return children;
}
export default CategoriesProvider;
