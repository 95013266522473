import * as React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Button,
  Grid,
  SelectChangeEvent,
  useMediaQuery,
  Stack,
  Typography,
} from '@mui/material';
import { GeoPoint } from 'firebase/firestore';
import { LocationOn } from '@mui/icons-material';
import { RoundedTextField } from '../textfield';
import { SelectRounded } from '../select';
import { MapAddress } from '../map';
import { Modal } from './Modal';
import { RoundedWideButton } from '../button';
import { AddressInput } from '../inputs';
import Icons from '../../assets/img/icons/icons';
import { Nullable } from '../../types';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setCurrentAddress } from '../../redux/reducers/address';
import { Address } from '../../dto';

type DialogProps = {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: () => void;
};

const useStyles = makeStyles(() => ({
  root: {},
  input: {
    border: '0.5px solid #C2C2C2',
    /* shadow-new-effect */

    boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '39.7458px',
  },
  btn: {
    background: '#FFDF00 !important',
    borderRadius: '20px !important',
    color: '#232323 !important',
    marginLeft: '13px !important',
    width: '120px',
  },
  icon: {
    width: 164,
    height: 163,
  },
  search: {
    background: '#FFFFFF',
    border: '0.5px solid #C2C2C2',
    boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: ' 39.7458px',
  },
  placeholder: {
    paddingLeft: 15,
  },
}));

const initialErrors = {
  city: null,
  address: null,
};

const getInitialValues = (city) => ({
  city: city?.id || '',
  address: '',
});

type ValuesTypes = {
  city: string | null;
  address: string | null;
};

export function AddressModal({ open, onClose }: DialogProps) {
  const cities = useAppSelector((state) => state.cities.allCities);
  const defaultCity = cities?.find((c) => c.is_default === true);
  const dispatch = useAppDispatch();
  const [searchData, setSearchData] = React.useState(getInitialValues(defaultCity));
  const [findLocation, setFindLocation] = React.useState<Nullable<google.maps.LatLngLiteral>>(null);
  const [outOfCity, setOutOfCity] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [aceptButton, setAceptButton] = React.useState(false);
  const [title, setTitle] = React.useState('Dirección');
  const [back, seBack] = React.useState(false);
  const [errors, setErrors] = React.useState<ValuesTypes>(initialErrors);
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:750px)');

  const findCity = React.useMemo(() => {
    const cityId = searchData.city;
    return cities?.find((city) => city.id === cityId);
  }, [searchData.city]);

  React.useEffect(() => {
    if (defaultCity) {
      setSearchData(getInitialValues(defaultCity));
    }
  }, [defaultCity]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };
  const handleSelect = (event: SelectChangeEvent): void => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };
  const handleConfirm = () => {
    if (searchData?.city.trim() === '') {
      setErrors({ ...errors, city: 'Se debe seleccionar una ciudad' });
      return;
    }
    if (searchData?.address.trim() === '') {
      setErrors({ ...errors, address: 'Es un parametro requerido' });
      return;
    }
    setErrors(initialErrors);
    setPage(2);
    setTitle('');
    seBack(true);
  };

  const onBackHandler = () => {
    setPage(1);
    setTitle('Dirección');
    setAceptButton(false);
    seBack(false);
  };

  const handleSearch = () => {
    if (findLocation) {
      dispatch(
        setCurrentAddress(
          new Address({
            location: new GeoPoint(findLocation.lat, findLocation.lng),
            address: searchData.address,
          }),
        ),
      );
      onClose();
      onBackHandler();
    }
  };

  return (
    <Modal
      onClose={onClose}
      open={open}
      onBack={onBackHandler}
      title={title}
      backButton={back}
      aceptButton={aceptButton}
      bottonText=""
    >
      {page === 1 && (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={8} md={8}>
            {cities && matches && (
              <AddressInput
                className={classes.search}
                onChange={handleChange}
                onChangeSelect={handleSelect}
                selectOptions={cities}
                values={searchData}
                onEnterPress={handleConfirm}
                errors={errors}
              />
            )}
            {cities && !matches && (
              <Grid container justifyContent="center" flexDirection="column">
                <Grid item marginBottom={2}>
                  <SelectRounded
                    id="select-city"
                    name="city"
                    error={Boolean(errors.city)}
                    fullWidth
                    helperText={Boolean(errors.city) && errors.city}
                    label="Ciudad"
                    onChange={handleSelect}
                    value={searchData.city}
                    selectList={cities}
                  />
                </Grid>
                <Grid item>
                  <RoundedTextField
                    id="search-address"
                    name="address"
                    type="text"
                    color="primary"
                    error={Boolean(errors.address)}
                    fullWidth
                    helperText={Boolean(errors.address) && errors.address}
                    label="Ingrese su dirección"
                    onChange={handleChange}
                    placeholder="Ingrese su dirección"
                    value={searchData?.address}
                    variant="outlined"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleConfirm();
                      }
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={2} md={2} sx={{ textAlign: 'center' }}>
            <Button
              className={classes.btn}
              onClick={() => {
                handleConfirm();
              }}
              sx={{ marginTop: matches ? '' : '20px !important' }}
            >
              Aceptar
            </Button>
          </Grid>
        </Grid>
      )}
      {page === 2 && !outOfCity && (
        <>
          <MapAddress
            address={`${findCity?.name || ''} ${searchData?.address}`}
            city={findCity}
            onCenterChange={setFindLocation}
            onOutOfCity={() => {
              setOutOfCity(true);
            }}
          />
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            p="16px"
          >
            <Typography variant="body2" style={{ textAlign: 'center' }}>
              <strong>Es esta tu ubicación?</strong>
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'center' }}>
              Para una mejor experiencia confirma tu direccion de entrega
            </Typography>
            <Stack direction="row" spacing={1}>
              <LocationOn />
              <Typography variant="body2">
                {`${findCity?.name || ''} ${searchData?.address}`}
              </Typography>
            </Stack>
            <RoundedWideButton
              color="primary"
              fullWidth
              label="Confirmar Ubicación"
              variant="contained"
              onClick={handleSearch}
            />
          </Stack>
        </>
      )}
      {outOfCity && (
        <Grid
          container
          sx={{ textAlign: 'center' }}
        >
          <Grid item xs={12}>
            <img src={Icons.ghost} alt="ghost icon" className={classes.icon} />
            <Typography variant="h3" sx={{ marginTop: '15px', marginBottom: '15px' }}>
              <strong>
                Ups! Al parecer en la dirección ingresada no hay servicio.
              </strong>
            </Typography>
            <RoundedWideButton
              color="primary"
              fullWidth={false}
              label="Aceptar"
              variant="contained"
              onClick={onClose}
            />
          </Grid>
        </Grid>
      )}
    </Modal>
  );
}

export default AddressModal;
