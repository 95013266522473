/* eslint-disable */
// @ts-nocheck
import {
  Container, Card, CardContent, Grid, Box,
} from '@mui/material';
import React from 'react';
import DOMPurify from 'dompurify';
import { makeStyles } from '@mui/styles';
import { OldPage } from '../../components';
import { useAgreements } from '../../hooks/useSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '1hv',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 20,
    paddingTop: 20,
  },
  doc: {
    color: theme.palette.text,
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5),
    },
    '& a': {
      color: theme.palette.text.primary,
    },
    '& ul': {
      paddingInlineStart: '40px',
    },
  },
}));

function PoliciyAndPrivay() {
  const classes = useStyles();
  const agreements = useAgreements();
  if (!agreements) return null;

  const html = agreements.privacy_policies;

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  return (
    <OldPage className={classes.root} title="Policy and Privacy">
      <Container maxWidth="md" maxHeight="md">
        <Card>
          <CardContent>
            <Grid>
              <Box className={classes.doc}>
                <div dangerouslySetInnerHTML={createMarkup(html)} />
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </OldPage>
  );
}

export default PoliciyAndPrivay;
