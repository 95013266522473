import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from '@mui/material';

const CustomizedCard = styled(Card)`
  box-shadow: 0px 4.91508px 4.91508px 1.91508px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  min-height: 125px;
  border: 0.1px solid rgb(191 199 213 / 40%);
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const DescriptionContent = styled(Typography)`
  display: block;
  display: -webkit-box;
  height: 2.6em;
  line-height: 1.3em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-break: break-word;
`;

const TitleContent = styled(Typography)`
  display: block;
  display: -webkit-box;
  height: 1.6em;
  line-height: 1.4em;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-break: break-word;
`;
export interface RestaurantProductCardProps {
  src?: string;
  alt?: string;
  name: string;
  description: string;
  price: number;
  onClick: (id: string) => void;
  id: string;
}

export function RestaurantProductCard({
  src,
  alt,
  id,
  name,
  description,
  price,
  onClick,
}: RestaurantProductCardProps) {
  return (
    <CustomizedCard onClick={() => onClick(id)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Box>
            <Tooltip title={name} arrow>
              <TitleContent fontWeight="bold" textTransform="capitalize">
                {name}
              </TitleContent>
            </Tooltip>
          </Box>
          <Box sx={{ height: '25px' }}>
            <Tooltip
              title={
                description
                  ? description[0].toUpperCase() + description.slice(1)
                  : ''
              }
              arrow
            >
              <DescriptionContent fontSize={14}>
                {description}
              </DescriptionContent>
            </Tooltip>
          </Box>
        </CardContent>
        <Box
          sx={{
            pl: 2,
            pb: 1,
            paddingTop: '-2px',
          }}
        >
          <Typography
            fontWeight="bold"
            sx={{ color: '#4E9788', paddingTop: '5px' }}
          >
            {` Bs. ${price.toFixed(2)} `}
          </Typography>
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{
          width: 100,
          height: 100,
          borderRadius: '8px',
          margin: '8px',
        }}
        image={src}
        alt={alt}
      />
    </CustomizedCard>
  );
}

export default RestaurantProductCard;
