import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { RoundedWideButton } from '../button';

export type DialogProps = {
  open: boolean;
  onAcepte?: () => void;
  onBack?: () => void;
  children?: React.ReactNode;
  onClose: () => void;
  title?: string;
  aceptButton?: boolean;
  bottonText?: string;
  backButton?: boolean;
};

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    paddingBottom: '20px',
    textAlign: 'center',
  },
  icon: {
    backgroundColor: '#ECFCF9 !important',
    color: '#4E9788 !important',
  },
  errorTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    paddingTop: 40,
  },
  dialog: {
    backgroundColor: '#1F6456 !important',
    opacity: '0.6 !important',
  },
  dialogActions: {
    justifyContent: 'center !important',
    paddingBottom: '30px !important',
  },
}));

export function Modal({
  open,
  onClose,
  onAcepte,
  onBack,
  children,
  aceptButton = true,
  bottonText,
  title,
  backButton = false,
}: DialogProps) {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      BackdropProps={{ className: classes.dialog }}
      maxWidth="sm"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {backButton && (
          <IconButton
            aria-label="close"
            onClick={onBack}
            className={classes.icon}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
            }}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.icon}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {title && (
          <div className={classes.title}>
            <Typography fontWeight="bold" variant="h6">
              {title}
            </Typography>
          </div>
        )}

        {children}

        {bottonText && (
          <Typography className={classes.errorTitle} fontWeight="bold">
            {bottonText}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {aceptButton && (
          <RoundedWideButton
            onClick={() => {
              if (onAcepte) {
                onAcepte();
              }
            }}
            label="Aceptar"
            variant="contained"
            style={{ color: '#BF8D20' }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
