import React, { useState } from 'react';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import ReactCodeInput from 'react-code-input';
import Loader from 'react-js-loader';
import axios from 'axios';
import {
  Box, Button, Card, Container, DialogContentText, FormHelperText, Grid, Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import authService from '../../services/auth';
import { BACKEND_URLS } from '../../constants';
import env from '../../env';
import texts from './constants.json';

export default function RequestDeleteAccount() {
  const [value, setValue] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [code, setCode] = useState('');
  const [uid, setUid] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const validatePhoneNumber = async () => {
    setLoading(true);
    try {
      const result = await axios.get(`${env.backendUrl + BACKEND_URLS.VERIFY_PHONE}+${value}`);
      if (result) {
        setUid(result.data.uid);
        await authService.getVerificationCode(`+${value}`);
        setConfirm(true);
      }
    } catch (e: any) {
      setErrorMessage(e?.response?.data?.message
          || 'Tenemos algunos problemas intente nuevamente en unos minutos.');
    }
    setLoading(false);
  };
  const confirmCode = async () => {
    setLoading(true);
    try {
      const result: any = await authService.verifyCode(`+${value}`, code);
      if (result?.data?.status === 'approved') {
        await authService.handleDeleteUser(uid);
        setOpenSuccess(true);
      }
    } catch (e: any) {
      setErrorMessage(e.message);
    }
    setLoading(false);
  };
  return (
    <Container maxWidth="sm">
      <Dialog
        open={openSuccess}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ color: '#56B19E' }} variant="h4">
            Cuenta eliminada correctamente
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tienes 30 días desde tu confirmación de eliminar tu cuenta para poder recuperarla, caso
            contrario tu cuenta será automáticamente eliminada de nuestra base de datos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSuccess(false);
              // @ts-ignore
              window.location.reload(false);
            }}
            className="btn btn-primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        paddingY={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="center"
            item
            xs={6}
          >

            <Card>
              <Box padding={2}>
                <Grid
                  container
                  spacing={2}

                >
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography>Elimina permanentemente tu cuenta</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box width={300}>
                      {!confirm ? (
                        <PhoneInput
                          countryCodeEditable={false}
                          country="bo"
                          value={value}
                          onChange={(phone: string, _: any, __: any, fv: string) => {
                            setValue(phone);
                            setFormattedValue(fv);
                            setErrorMessage('');
                          }}
                        />
                      ) : (
                        <Box width={300}>
                          <Typography variant="h5">{formattedValue}</Typography>
                          <ReactCodeInput
                            value={code}
                            onChange={(text) => {
                              if (/^\d+$/.test(text)) {
                                setCode(text);
                              }
                            }}
                            type="number"
                            fields={6}
                            inputMode="numeric"
                            name=""
                          />
                        </Box>
                      )}
                      {Boolean(errorMessage) && (
                      <FormHelperText error>
                        {errorMessage}
                      </FormHelperText>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {texts.message}
                    </Typography>
                    <br />
                    <Typography variant="button">
                      {texts.alert}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {loading ? <Loader type="ping-cube" bgColor="#FEC512" size={100} />
                      : (
                        <Button
                          disabled={!value || value?.length < 8}
                          onClick={confirm ? confirmCode : validatePhoneNumber}
                          className="btn btn-primary"
                        >
                          {confirm ? 'Confirmar' : 'Eliminar cuenta'}
                        </Button>
                      )}
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

    </Container>
  );
}
