/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef,
  useEffect, useCallback,
} from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import track from '../../utils/analytics';

type Props = { title: string, children: React.ReactNode }
export const OldPage = forwardRef < {}, Props >(({
  title,
  children,
  ...rest
}, ref) => {
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div
      // @ts-ignore
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

export default OldPage;
