import moment from 'moment-timezone';
import { Timestamp } from 'firebase/firestore';
import { DAYS } from '../constants/index';

interface ScheduleDateProps {
  end_time: Timestamp
  start_time: Timestamp
}

interface ScheduleProps {
  open: boolean
  schedule: Array<ScheduleDateProps>
  journey: string
}

function createMomentOnBolivianTime(date?: string, format?: string) {
  const currentDate = moment().format('YYYY DD MM HH:mm');
  return moment.tz(date || currentDate, format || 'YYYY MM HH:mm', 'America/La_Paz');
}

function getCurrentDay() {
  const dataBolivia = moment().toDate();
  const day = new Intl.DateTimeFormat('es-BO', { weekday: 'long' }).format(
    dataBolivia,
  );
  return day.toUpperCase();
}

function getCurrentJourney(schedule: Array<ScheduleProps>) {
  if (schedule) {
    let journeyIsOpen: string = 'CERRADO';
    const arrayJourney: Array<String> = [];
    const currentHour = moment().format('HH:mm');
    const daysEnglish = DAYS.find((day) => day.value.toUpperCase() === getCurrentDay());
    const journey = schedule.find((value) => value.journey === daysEnglish?.en_us);
    if (journey?.open) {
      journey?.schedule?.forEach((value) => {
        if (value.start_time && value.end_time) {
          const start = new Date(value.start_time.seconds * 1000);
          const end = new Date(value.end_time.seconds * 1000);
          const journeyStart = moment(start).format('HH:mm');
          const journeyEnd = moment(end).format('HH:mm');
          if (new Date(`01/01/2011 ${currentHour}`) > new Date(`01/01/2011 ${journeyStart}`)) {
            if (new Date(`01/01/2011 ${currentHour}`) < new Date(`01/01/2011 ${journeyEnd}`)) {
              journeyIsOpen = 'ABIERTO';
            } else {
              journeyIsOpen = `ABRE A LAS ${journeyStart} ${moment(start).format('A')}`;
              arrayJourney.push(journeyIsOpen);
            }
          } else {
            journeyIsOpen = `ABRE A LAS ${journeyStart} ${moment(start).format('A')}`;
            arrayJourney.push(journeyIsOpen);
          }
        }
      });
    }
    return journeyIsOpen !== 'ABIERTO' ? `${arrayJourney[0] || 'CERRADO'}` : journeyIsOpen;
  }
  return null;
}

function getJourneyDay(schedule: ScheduleDateProps) {
  if (schedule.start_time && schedule.end_time) {
    const start = new Date(schedule.start_time.seconds * 1000);
    const end = new Date(schedule.end_time.seconds * 1000);
    const journeyStart = moment(start).format('HH:mm');
    const journeyEnd = moment(end).format('HH:mm');
    return `${journeyStart} - ${journeyEnd}`;
  }
  return null;
}

export default {
  createMomentOnBolivianTime,
  getCurrentDay,
  getCurrentJourney,
  getJourneyDay,
};
