/* eslint-disable */
import React, {Component} from 'react';
import './Carta.css';
import ReactCardFlip from "react-card-flip";
import {Box, Grid} from "@mui/material";

export default class Carta extends Component {
  render() {
    return (
      <Grid item className="carta" onClick={this.props.seleccionarCarta}>
        <ReactCardFlip
          isFlipped={this.props.estaSiendoComparada || this.props.fueAdivinada}
          flipDirection="vertical"
        >
          <Box className="portada" />
          <Box className="contenido">
            <i className={`fa ${this.props.icono} fa-5x`} />
          </Box>
        </ReactCardFlip>
      </Grid>
    );
  }
}
