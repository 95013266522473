import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Divider,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { Timestamp, GeoPoint } from 'firebase/firestore';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Nullable } from '../../types';
import ScheduleOnlyView from '../schedule/ScheduleOnlyView';
import { DAYS } from '../../constants';

const useStyles = makeStyles(() => ({
  icon: {
    backgroundColor: '#ECFCF9 !important',
    color: '#4E9788 !important',
  },
}));

interface ScheduleDateProps {
  end_time: Timestamp
  start_time: Timestamp
}

interface ScheduleProps {
  open: boolean
  schedule: Array<ScheduleDateProps>
  journey: string
}

interface SocialNetworkProps {
  url_facebook?: string
  url_instagram?: string
  url_other?: string
}

interface InfoSubsidiaryProps {
  address: string
  approvalsPercentage: number
  schedule: Array<ScheduleProps>
  location: Nullable<GeoPoint>
  onClose: () => void
  categoriesNames: string
  socialNetworks: SocialNetworkProps
  subsidiary_name: string
  restaurant_name: string
}

const CustomizeBox = styled(Container)(({ theme }) => ({
  width: '717px',
  backgroundColor: 'white',
  padding: '0px !important',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const ContainerInfo = styled(Grid)`
  width: 100%;
`;

const TypographyTitle = styled(Typography)`
  font-weight: bold;
  font-size: 30px;
`;

const TypographyDirection = styled(Typography)`
  font-size: 16px;
`;

const TypographySchedule = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
`;

const CustomDivider = styled(Divider)`
  height: 0px;
  background: rgba(0,0,0,0.13) 0% 0% no-repeat padding-box;
`;

function InfoSubsidiaryModal({
  address,
  approvalsPercentage,
  schedule,
  location,
  onClose,
  categoriesNames,
  socialNetworks,
  subsidiary_name = '',
  restaurant_name = '',
}: InfoSubsidiaryProps) {
  const classes = useStyles();
  const orderSchedule: Array<ScheduleProps> = [];

  function handleOrderSchedule() {
    DAYS.forEach((day) => {
      const newSchedule = schedule?.find((value) => value.journey === day.en_us);
      if (newSchedule) {
        orderSchedule.push({
          journey: day.value,
          open: newSchedule.open,
          schedule: newSchedule.schedule,
        });
      }
    });
    return orderSchedule;
  }

  function handleLocation() {
    if (location) {
      return { lat: location.latitude, lng: location.longitude };
    }
    return { lat: 0, lng: 0 };
  }

  function getTagNameUrl(url: string) {
    const tagName = url.split('/');
    return tagName[3];
  }

  return (
    <CustomizeBox>
      <Box height="250px" width="100%">
        <LoadScript
          googleMapsApiKey="AIzaSyBex46zHORVn63VFPsYrn4elJPhL3sxCWA"
          libraries={['places']}
        >
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            zoom={15}
            center={handleLocation()}
            options={{
              disableDefaultUI: true,
            }}
          >
            <Marker position={handleLocation()} />
          </GoogleMap>
        </LoadScript>
        <Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.icon}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box ml={4} mr={4} mt={2} display="flex" justifyContent="center">
        <ContainerInfo>
          <TypographyTitle variant="body2" textTransform="capitalize">
            {`${restaurant_name} - ${subsidiary_name}`}
          </TypographyTitle>
          <Box display="flex" alignItems="center" mb={3}>
            <TypographyDirection variant="body2" fontWeight="bold">
              Dirección:
            </TypographyDirection>
            <TypographyDirection variant="body2" ml={1} textTransform="capitalize">
              {address}
            </TypographyDirection>
          </Box>
          <CustomDivider variant="fullWidth" />
          <Typography variant="body2" fontSize="16px" mt={2.5} mb={2.5}>
            {categoriesNames}
          </Typography>
          <CustomDivider variant="fullWidth" />
          <Box display="flex" justifyContent="space-between" mt={3} mb={3}>
            <Box display="flex" alignItems="center" width="100%">
              <ThumbUpOutlinedIcon color="primary" />
              <Box ml={1}>
                <Typography variant="body1" ml={1}>
                  {`${Math.trunc(approvalsPercentage || 0)}%`}
                </Typography>
              </Box>
            </Box>
            <Box>
              {socialNetworks && socialNetworks?.url_facebook && (
                <Box display="flex" mb={2} alignItems="flex-end">
                  <FacebookIcon color="secondary" />
                  <Link
                    href={socialNetworks?.url_facebook}
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    color="secondary"
                  >
                    {`@${getTagNameUrl(socialNetworks?.url_facebook)}`}
                  </Link>
                </Box>
              )}
              {socialNetworks && socialNetworks?.url_instagram && (
                <Box display="flex" alignItems="flex-end">
                  <InstagramIcon color="secondary" />
                  <Link
                    href={socialNetworks?.url_instagram}
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    color="secondary"
                  >
                    {`@${getTagNameUrl(socialNetworks?.url_instagram)}`}
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
          <CustomDivider variant="fullWidth" />
        </ContainerInfo>
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column" mt={3}>
        <TypographySchedule variant="body2">
          Horarios de atención
        </TypographySchedule>
        <Box width="100%" mb={5} mt={2} display="flex" justifyContent="center">
          <ScheduleOnlyView schedule={handleOrderSchedule()} />
        </Box>
      </Box>
    </CustomizeBox>
  );
}

export default InfoSubsidiaryModal;
