import React from 'react';
import Dialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import ModalTitle from './modalTitle';

interface DialogProps extends MuiDialogProps {
  onClose: () => void;
}

export function ModalLayout({
  fullWidth,
  maxWidth, open, onClose, children, title,
}:DialogProps) {
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
    >
      <ModalTitle id="dialog-title" onClose={onClose}>
        {title}
      </ModalTitle>
      {children}
    </Dialog>
  );
}

export default ModalLayout;
