import React, { useState, useEffect } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { GeoPoint } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { ModalLayout } from '../../components/modal';
import { RoundedWideButton } from '../../components/button';
import { useAppDispatch } from '../../hooks/redux';
import { setCurrentAddress } from '../../redux/reducers/address';
import { Address, City } from '../../dto';
import { Nullable } from '../../types';
import { MapAddress } from '../../components/map';
import Icons from '../../assets/img/icons/icons';

type MapAddressModalProps = {
  open: boolean;
  onClose: () => void;
  address: string;
  city: City | undefined;
};

export function MapAddressModal({
  open,
  onClose,
  address,
  city,
}: MapAddressModalProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [findLocation, setFindLocation] = useState<Nullable<google.maps.LatLngLiteral>>(null);
  const [outOfCity, setOutOfCity] = useState<boolean>(false);
  const handleClick = () => {
    if (findLocation) {
      dispatch(
        setCurrentAddress(
          new Address({
            location: new GeoPoint(findLocation.lat, findLocation.lng),
            address,
          }),
        ),
      );
      navigate('../restaurants', { replace: false });
    }
  };

  useEffect(() => {
    setOutOfCity(false);
  }, [open]);

  const handleClose = () => {
    setOutOfCity(false);
    onClose();
  };

  return (
    <ModalLayout maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      {outOfCity && (
        <Grid
          container
          sx={{ textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' }}
          spacing={8}
          pb={8}
        >
          <Grid item xs={12}>
            <img src={Icons.ghost} alt="ghost icon" width={164} height={163} />
            <Typography variant="h3">
              <strong>
                Ups! Al parecer en la dirección ingresada no hay servicio.
              </strong>
            </Typography>
            <RoundedWideButton
              color="primary"
              fullWidth={false}
              label="Aceptar"
              variant="contained"
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      )}
      {!outOfCity && (
        <>
          <MapAddress
            address={`${city?.name || ''} ${address}`}
            city={city}
            onCenterChange={setFindLocation}
            onOutOfCity={() => {
              setOutOfCity(true);
            }}
          />
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            p="16px"
          >
            <Typography variant="body2" style={{ textAlign: 'center' }}>
              <strong>Es esta tu ubicación?</strong>
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'center' }}>
              Para una mejor experiencia confirma tu direccion de entrega
            </Typography>
            <Stack direction="row" spacing={1}>
              <LocationOn />
              <Typography variant="body2">
                {`${city?.name || ''} ${address}`}
              </Typography>
            </Stack>
            <RoundedWideButton
              color="primary"
              fullWidth
              label="Confirmar Ubicacion"
              variant="contained"
              onClick={handleClick}
            />
          </Stack>
        </>
      )}
    </ModalLayout>
  );
}
