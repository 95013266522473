import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, List } from '@mui/material';
import ProductCategory from '../../dto/productCategory';

const CustomButton = styled(Button)(() => ({
  background: '#DCF9F4',
  margin: '7px',
  display: 'inline-block',
  boxShadow: '1px 1px 6px rgba(2, 151, 119, 0.37)',
  borderRadius: '6px',
  color: ' #6A9F92',
  '&:hover': {
    background: '#B2E7D8',
  },
  '&.active': {
    background: '#B2E7D8',
  },
}));

const CustomList = styled(List)(() => ({
  overflow: 'auto',
  whiteSpace: 'nowrap',
  marginLeft: '20px',
  marginRight: '20px',
}));

export interface CategoryButtonsProps {
  options: ProductCategory[];
  onClick: (selected: ProductCategory) => void;
}

interface Category {
  category: string;
  selected: boolean;
  label: string;
}

export function CategoryButtons({ options, onClick }: CategoryButtonsProps) {
  const [categories, setCategories] = React.useState<Category[]>([
    { selected: true, category: 'all', label: 'Todos' },
    ...options.map((cat) => ({
      selected: false,
      category: cat.value,
      label: cat.label,
    })),
  ]);

  const handleSelect = (selectedOption: Category) => {
    onClick(
      new ProductCategory({
        label: selectedOption.label,
        value: selectedOption.category,
      }),
    );
    const states: Category[] = [];
    categories.forEach((option: Category) => {
      if (option.category === selectedOption.category) {
        states.push({
          category: option.category,
          selected: true,
          label: option.label,
        });
      } else {
        states.push({
          category: option.category,
          selected: false,
          label: option.label,
        });
      }
    });

    setCategories(states);
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CustomList>
        {categories.map((cat, index) => (
          <CustomButton
            key={cat.category + index.toString()}
            sx={cat.selected ? { background: '#B2E7D8' } : {}}
            onClick={() => {
              handleSelect(cat);
            }}
          >
            {cat.label}
          </CustomButton>
        ))}
      </CustomList>
    </Box>
  );
}

export default CategoryButtons;
