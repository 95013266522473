import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../constants';

export const useRestaurantsBannersForXpress = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANTS_SUMMARY,
      doc: 'xpress_dashboard',
      storeAs: 'xpress_dashboard',
    },
  ]);
  const restaurants = useSelector((state:any) => state.firestore.ordered.xpress_dashboard);

  if (restaurants) {
    return restaurants[0];
  }

  return null;
};

export const useRestaurantById = (restId: string = '') => {
  const restaurant = useSelector((state:any) => state.firestore.data.restaurant_id);
  const query = restId ? [{
    collection: COLLECTIONS.RESTAURANT,
    doc: restId,
    storeAs: 'restaurant_id',
  }] : [];
  useFirestoreConnect(query);
  if (restId) {
    if (restaurant) {
      return restaurant;
    }
  }
  return null;
};

export const useRestaurantByRestName = (rest_name:string = '') => {
  const restaurant_name = rest_name.toLowerCase();

  if (restaurant_name) {
    useFirestoreConnect([
      {
        collection: COLLECTIONS.RESTAURANT,
        where: ['normalized_name', '==', restaurant_name],
        storeAs: 'restaurant_by_name',
      },
    ]);
    const restaurant = useSelector((state:any) => state.firestore.ordered.restaurant_by_name);

    if (restaurant) {
      return restaurant[0];
    }
    return null;
  }
  return null;
};
