import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import HomeModel from '../../../models/home/models/homeModel';
import { Nullable } from '../../../types';

type State = {
  data:Nullable<HomeModel>,
  loading:boolean
}
const initialState: State = {
  data: null,
  loading: true,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomeData: (state, action:PayloadAction<HomeModel>) => {
      state.loading = false;
      state.data = action.payload;
    },
    setHomeDataLoading: (state, action:PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setHomeData } = homeSlice.actions;
export default homeSlice.reducer;
export const homeReducerTransform = createTransform(
  (toSave:State) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
    data: toRehydrated.data ? new HomeModel(toRehydrated.data) : null,
  }),
  { whitelist: ['home'] },
);
