import { Navigate, Route } from 'react-router-dom';
import React from 'react';

const RootRoutes = (
  <Route path="/">
    <Route index element={<Navigate to="/welcome" replace />} />
  </Route>
);

export default RootRoutes;
