/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Box } from '@mui/material';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

type ModalProps = {
    children?: React.ReactNode;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
};

function SimpleCarrousel({
  children, xs = 3, sm = 5, md = 7, lg = 9, xl = 11,
}:ModalProps) {
  const responsive = {
    xl: {
      breakpoint: { max: 3000, min: 1920 },
      items: xl,
    },
    lg: {
      breakpoint: { max: 1920, min: 1440 },
      items: lg,
    },
    md: {
      breakpoint: { max: 1440, min: 1024 },
      items: md,
    },
    sm: {
      breakpoint: { max: 1024, min: 464 },
      items: sm,
    },
    xs: {
      breakpoint: { max: 464, min: 0 },
      items: xs,
    },
  };

  return (
    <Carousel
      swipeable
      draggable
      responsive={responsive}
      infinite={false}
      keyBoardControl={false}
      containerClass="carousel-container"
      removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
      itemClass="carousel-item-padding-40-px"
    >
      {children}
    </Carousel>
  );
}

export default SimpleCarrousel;
