import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Typography, useMediaQuery, Button,
} from '@mui/material';
import { ImageButton } from '../../components/button';
import { ROUTES } from '../../routes/constantsRoutes';
import env from '../../env';

const CustomizedButton = styled(Button)`
  padding-inline: 30px;
  padding-block: 10px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: none;
  text-align: center;
  @media (max-width: 450px) {
    padding-inline: 20px;
    padding-block: 6px;
  }
`;

const Container = styled(Box)`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 80px;
`;

function HeaderWelcome() {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <header className="banner navbar navbar-default" role="banner">
      <nav role="navigation">
        <div
          style={{
            padding: '1% 2% 0 2%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ImageButton
            src="./assets/img/logo-white.png"
            alt="logo-xpress"
            href={ROUTES.WELCOME}
            width="auto"
            height={isMobile ? '40px' : '60px'}
          />
          <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
            <CustomizedButton
              disabled={false}
              fullWidth={false}
              href={`${env.merchantUrl}/login`}
              onClick={() => {}}
            >
              Iniciar sesión
            </CustomizedButton>
            <CustomizedButton
              color="primary"
              variant="contained"
              disabled={false}
              fullWidth={false}
              href={`${env.merchantUrl}/home`}
              onClick={() => {}}
            >
              Inscríbete ya!
            </CustomizedButton>
          </Box>
        </div>
      </nav>

      <img
        src="./assets/img/xpress-burger-v7.png"
        style={isMobile ? {
          height: '100%',
          position: 'absolute',
          bottom: 0,
          right: 0,
        } : {}}
        className="single-burger"
              // @ts-ignore
        parallax="horizontal"
        alt="xpress-burger"
      />
      <Container>
        <Box m={8} style={{ marginBottom: '5%' }}>
          <Typography
            variant="h1"
            color="white"
            textTransform="uppercase"
          >
            La primera app con servicios de

          </Typography>
          <Typography
            variant="h1"
            fontSize={isMobile ? 30 : 95}
            color="white"
            textTransform="uppercase"
          >
            Delivery
          </Typography>
          <Typography
            variant="h1"
            fontSize={isMobile ? 30 : 95}
            color="white"
            textTransform="uppercase"
          >
            & pickup
          </Typography>
          <Typography
            variant="h1"
            fontSize={isMobile ? 30 : 60}
            color="white"
            textTransform="uppercase"
          >
            En Bolivia
          </Typography>
          <CustomizedButton
            style={{ marginTop: '2%' }}
            color="primary"
            size="medium"
            variant="contained"
            fullWidth={false}
            href={`${env.merchantUrl}/home`}
            onClick={() => {}}
          >
            ¡Inscribe tu comercio aquí!
          </CustomizedButton>
        </Box>
      </Container>
    </header>
  );
}

export default HeaderWelcome;
