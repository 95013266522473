import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Typography, Stack, useMediaQuery,
} from '@mui/material';
import { ImageButton } from '../../components/button';
import { LINK_APPS } from '../../constants';

const ContainerSection = styled(Box)`
  padding-block: 70px;
  padding-inline: 85px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1000px) {
    justify-content: center;
    padding-inline: 25px;
    padding-block: 50px;
  }
`;

export default function DownloadSection() {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <ContainerSection>
      <Stack
        direction="column"
        spacing={isMobile ? 4 : 8}
        maxWidth="625px"
        width="100%"
        justifyContent="center"
      >
        <Typography variant={isMobile ? 'body1' : 'h3'} style={{ textAlign: 'center' }}>
          <strong>
            Descarga Xpress y disfruta de toda la eficiencia, facilidad,
            y conveniencia de nuestros servicios
          </strong>
        </Typography>
        <Stack direction="row" width="100%" justifyContent="space-between" spacing={1}>
          <ImageButton
            src="./assets/img/googleplay.png"
            alt="xpress-googleplay"
            href={LINK_APPS.android}
            width="auto"
            height={isMobile ? '55' : '80'}
          />
          <ImageButton
            src="./assets/img/appstore.png"
            alt="xpress-appstore"
            href={LINK_APPS.ios}
            width="auto"
            height={isMobile ? '55' : '80'}
          />
        </Stack>
      </Stack>
      <Box style={isMobile ? { display: 'none' } : {}}>
        <img
          src="./assets/img/celulares.png"
          width="auto"
          height="480"
          alt="Banner Xpress"
        />
      </Box>
    </ContainerSection>
  );
}
