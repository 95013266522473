import { getDistance } from 'geolib';
import { GeoPoint } from 'firebase/firestore';
import { useAppSelector } from '../hooks/redux';
import { COOK_AVERAGE_TYPE } from '../constants/index';

interface CookAverageProps {
  average: number
  no_orders: false
  type: string
}

export function distancePickUp(locationSubsidiary?: GeoPoint) {
  const locationUser = useAppSelector((store) => store.address.selectedAddress?.location);
  if (locationUser && locationSubsidiary) {
    const distance = getDistance(
      {
        latitude: locationSubsidiary?.latitude,
        longitude: locationSubsidiary?.longitude,
      },
      {
        latitude: locationUser?.latitude,
        longitude: locationUser?.longitude,
      },
    );
    return distance / 1000;
  }
  return null;
}

export function getPickupTime(cooked_average: CookAverageProps) {
  const { type } = cooked_average;
  const [params] = COOK_AVERAGE_TYPE.filter((tp) => tp.type === type);
  const { min, max } = params;
  const low = min + 10;
  const high = max + 10;
  return `${low.toFixed()}-${high.toFixed()}`;
}

function extraDistanceTime(deliveryDistance: number) {
  if (deliveryDistance >= 5000) {
    return (Math.trunc(deliveryDistance / 1000) - 4) * 5;
  }
  return 0;
}

export function getDeliveryTime(cooked_average: CookAverageProps, delivery_distance: number) {
  const { type } = cooked_average;
  const [params] = COOK_AVERAGE_TYPE.filter((tp) => tp.type === type);
  const { min, max } = params;
  const low = min + extraDistanceTime(delivery_distance || 0) + 15;
  const high = max + extraDistanceTime(delivery_distance || 0) + 15;
  return `${low.toFixed()}-${high.toFixed()}`;
}

export default {
  distancePickUp,
  getPickupTime,
  getDeliveryTime,
};
