import { Environment } from './type';

const ProductionEnv: Environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyBlhBl_GNqU14UTZmfAllGLjLAXOQhJ1y8',
    authDomain: 'xpress-production.firebaseapp.com',
    databaseURL: 'https://xpress-production.firebaseio.com',
    projectId: 'xpress-production',
    storageBucket: 'xpress-production.appspot.com',
    messagingSenderId: '455344764199',
    appId: '1:455344764199:web:a0b54a5acd7608da39e502',
    measurementId: 'G-KBWFDMBFWC',
  },
  cloudFunctionsUrl: 'https://us-central1-xpress-production.cloudfunctions.net',
  xpressWebJsUrl: 'https://xpress-web-js.firebaseapp.com',
  companyName: 'XPRESS Technologies',
  merchantUrl: 'https://dashboard.xpressbolivia.com',
  adminUrl: 'https://xpressbolivia.com',
  googleAnalytics: 'UA-237277188-1',
  backendUrl: 'https://xpress-api-nnzydfbpnq-uc.a.run.app',
};

export default ProductionEnv;
