import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Card, CardMedia, Stack, Typography,
} from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import IconButton from '../button/onlyIconButton';
import { useAppSelector } from '../../hooks/redux';
import Icons from '../../assets/img/icons';
import Images from '../../assets/img/images';

const CustomizedCard = styled(Card)`
  box-shadow: 0px 4.91508px 4.91508px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  width: 100%;
  min-height: 240px;
  cursor: pointer;
`;

const CustomCardContent = styled(Card)`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const Circle = styled(Box)`
  background: #56B19E;
  border-radius: 20px;
  height: 3px;
  width: 3px;
`;

export interface RestaurantCardProps {
  src?:string,
  alt?:string,
  restaurantName: string,
  subsidiaryName: string,
  categories: string[],
  likes?: number,
  approvalsPercentage?: number,
  price: number,
  distance: number,
  handleClik: () => void,
}

export function RestaurantCard({
  src,
  alt = '',
  restaurantName,
  subsidiaryName,
  categories = [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  likes = 0,
  approvalsPercentage = 0,
  price,
  distance,
  handleClik = () => {},
}:RestaurantCardProps) {
  const allCategories = useAppSelector((store) => store.categories.allCategories);

  const categoriesNames = useMemo(() => {
    let categoriesNamesResult:string = '';
    const categoriesName: string[] = [];
    if (allCategories && categories.length > 0) {
      categories.forEach((categoryId) => {
        const existsCategory = allCategories.find((category) => category.id === categoryId);
        if (existsCategory) {
          categoriesName.push(existsCategory.name);
        }
      });
      categoriesNamesResult = categoriesName.join(' | ');
    }
    return categoriesNamesResult !== '' ? categoriesNamesResult : 'Otros';
  }, [allCategories, categories]);

  return (
    <CustomizedCard onClick={handleClik}>
      <CardMedia
        component="img"
        height="154"
        src={src}
        alt={alt}
        onError={(e) => {
          e.target.src = Images.placeholderBig;
        }}
      />
      <CustomCardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 'bold',
              width: '85%',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            { `${restaurantName} - ${subsidiaryName}`}
          </Typography>
          {/* <IconButton color="secondary" disabled={false} size="small" onClick={() => {}}>
            {likes > 0 ? (
              <FavoriteIcon sx={{ color: '#56B19E' }} />
            ) : (
              <FavoriteBorderIcon sx={{ color: '#56B19E' }} />
            )}
          </IconButton> */}
        </Stack>
        <Typography sx={{
          fontSize: 12,
          width: '85%',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          color: '#707070#707070',
        }}
        >
          {categoriesNames}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <img
              src={Icons.likeYellow}
              alt="like icon"
              width="14px"
              height="14px"
            />
            <Typography sx={{ color: '#707070', fontSize: 12, fontWeight: 400 }}>
              {`${approvalsPercentage.toFixed(2)}%`}
            </Typography>
            <Circle />
            <Typography sx={{ color: '#707070', fontSize: 12, fontWeight: 400 }}>
              {`Bs. ${price.toFixed(2)} Envío`}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ color: '#56B19E', fontSize: 12, fontWeight: 700 }}>
              {distance <= 1000 ? `${distance} m` : `${Math.round(distance / 1000)} km`}
            </Typography>
          </Stack>
        </Stack>
      </CustomCardContent>
    </CustomizedCard>
  );
}

export default RestaurantCard;
