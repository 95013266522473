import React from 'react';
import {
  AppBar, Box, Toolbar, Typography, useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@emotion/styled';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { makeStyles } from '@mui/styles';
import ImageButton from '../button/imageButton';
import Icons from '../../assets/img/icons/icons';
import LeftIconButton from '../button/iconButton';
import { IconTextField } from '../textfield';
import OnlyIconButton from '../button/onlyIconButton';
import { AddressModal, SearchModal } from '../modals';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setSearchTerm } from '../../redux/reducers/navBar';

const CustomizedAppBar = styled(AppBar)`
  background-color: #ffffff;
`;
const useStyles = makeStyles(() => ({
  root: {},
  search: {
    boxShadow: '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    height: '40px',
  },
  btnIcon: {
    background: '#F9DE39 !important',
    color: '#232323 !important',
    borderRadius: '12px !important',
    'text-transform': 'none !important',
    height: '43px',
  },
}));

export default function TopNavBar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openAdress, setOpenAdress] = React.useState(false);
  const matches = useMediaQuery('(min-width:750px)');
  const dispatch = useAppDispatch();
  const selectedAddress = useAppSelector((store) => store.address.selectedAddress);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const search = async (term: string) => {
    if (term) {
      dispatch(setSearchTerm(term));
    } else {
      dispatch(setSearchTerm(''));
    }
    setOpen(false);
  };

  return (
    <CustomizedAppBar
      position="relative"
      color="secondary"
      elevation={0}
      sx={{ borderBottom: 2, borderColor: 'divider' }}
    >
      <Toolbar>
        {matches ? (
          <Box>
            <ImageButton src={Icons.logo} href="/" />
          </Box>
        ) : (
          <Box>
            <ImageButton src={Icons.logo1} href="/" />
          </Box>
        )}

        <Typography />
        <Box sx={{ flexGrow: 1 }} />
        {matches ? (
          <>
            <LeftIconButton
              className={classes.btnIcon}
              variant="contained"
              size="small"
              icon={<LocationOnOutlinedIcon />}
              label={selectedAddress ? selectedAddress.address.substr(0, 15) : ''}
              onClick={() => {
                setOpenAdress(true);
              }}
            />
            <IconTextField
              size="small"
              className={classes.search}
              placeholder="Buscar"
              onChange={(event) => {
                search(event.target.value);
              }}
            />
          </>
        ) : (
          <>
            <LeftIconButton
              size="small"
              className={classes.btnIcon}
              icon={<LocationOnOutlinedIcon />}
              label={selectedAddress ? selectedAddress.address.substr(0, 15) : ''}
              onClick={() => {
                setOpenAdress(true);
              }}
            />
            <OnlyIconButton onClick={handleClickOpen}>
              <SearchIcon />
            </OnlyIconButton>
          </>
        )}
      </Toolbar>
      <SearchModal
        open={open}
        onClose={handleClose}
        onAcepte={(term: string) => {
          search(term);
        }}
      />
      <AddressModal
        open={openAdress}
        onClose={() => {
          setOpenAdress(false);
        }}
      />
    </CustomizedAppBar>
  );
}
