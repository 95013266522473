import React from 'react';
import Hero from './hero';
import CommerceSection from './commerceSection';
import DowloadSection from './dowloadSection';
import Footer from '../../components/footer';

export function LandingPage() {
  return (
    <div>
      <Hero />
      <CommerceSection />
      <DowloadSection />
      <Footer />
    </div>
  );
}

export default LandingPage;
