import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const CustomizedButton = styled(Button)`
  padding-inline: 45px;
  padding-block: 10px;
  border-radius: 20px;
  font-weight: bold;
  @media (max-width: 450px) {
    padding-inline: 31px;
    padding-block: 6px;
  }
`;

export interface ButtonProps {
  color?:'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined,
  label:string,
  variant?:'text' | 'outlined' | 'contained' | undefined,
  disabled?:boolean,
  className?:string,
  fullWidth?:boolean,
  size?:'small' | 'medium' | 'large' | undefined,
  onClick: () => void,
  style?: React.CSSProperties,
}

export function RoundedWideButton({
  color, label, variant, disabled, className, fullWidth, size, onClick, style,
}: ButtonProps) {
  return (
    <CustomizedButton
      className={className}
      color={color}
      size={size}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      style={style}
    >
      {label}
    </CustomizedButton>
  );
}

export default RoundedWideButton;
