import React from 'react';

export interface ImageButtonProps {
  src:string,
  alt?:string,
  href:string,
  width?:string,
  height?:string,
  className?:string,
}

export function ImageButton({
  src, alt, href, width, height, className,
}:ImageButtonProps) {
  return (
    <a href={href} className={className}>
      <img alt={alt} src={src} width={width} height={height} />
    </a>
  );
}

export default ImageButton;
