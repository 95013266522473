import { styled } from '@mui/material/styles';
import {
  Box, Button, Grid, Typography, useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import Slider from 'react-slick';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import env from '../../env';
import DownloadApps from '../../components/downloadApps/DownloadApps';

import logos from './logos';

const CustomizedButton = styled(Button)`
  padding-inline: 30px;
  padding-block: 10px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: none;
  text-align: center;
  @media (max-width: 450px) {
    padding-inline: 20px;
    padding-block: 6px;
  }
`;

const ContainerArrowPrev = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  cursor: 'pointer',
  backgroundColor: '#ecfcf9',
  width: '70px',
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  right: '150px',
  top: '300px',
  [theme.breakpoints.down('sm')]: {
    width: '50px',
    height: '50px',
    marginLeft: '12px',
  },
}));

const ContainerArrowNext = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  cursor: 'pointer',
  backgroundColor: '#ecfcf9',
  width: '70px',
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  right: '50px',
  top: '300px',
  [theme.breakpoints.down('sm')]: {
    width: '50px',
    height: '50px',
    marginRight: '12px',
  },
}));

const ContainerArrows = styled(Box)(({ theme }) => ({
  width: '200px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    top: '130px',
    right: '0px',
  },
}));

const ArrowNext = styled(ArrowForwardIcon)(({ theme }) => ({
  height: '40px',
  width: '40px',
  [theme.breakpoints.down('sm')]: {
    width: '20px',
    height: '20px',
  },
}));

const ArrowPrev = styled(ArrowBackIcon)(({ theme }) => ({
  height: '40px',
  width: '40px',
  [theme.breakpoints.down('sm')]: {
    width: '20px',
    height: '20px',
  },
}));

const SliderRestaurantsSettings = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 8,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
function ContentWelcome() {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const isMobile = useMediaQuery('(max-width:1094px)');
  const [activeStep, setActiveStep] = useState(0);

  const data = [
    {
      id: 0,
      title: 'Llegarás a nuevos clientes',
      description: `Nuestras distancias de entrega están entre las más
      amplias de la industria y te ayudarán llegar a muchos
      más clientes.`,
      image: './assets/img/slider/carrusel1.jpg',

    },
    {
      id: 1,
      title: 'Mejorarás tus márgenes',
      description: `Nuestras comisiones te permiten mantener mejores
      márgenes de ganancias para tu negocio.`,
      image: './assets/img/slider/carrusel2.jpg',

    },
    {
      id: 2,
      title: 'Aumentarás presencia de marca',
      description: `El estilo gráfico y usabilidad de nuestras plataformas
      digitales te ayudará a tener mejor presencia de marca
      y llamar la atención de clientes.`,
      image: './assets/img/slider/carrusel3.jpg',

    },
  ];

  return (
    <div>
      <div className="wrap-bubble">
        <img
          src="./assets/img/bubble-blue-v4.png"
          className="bubble-blue"
          alt="xpress-bubble-blue"
        />
        <div className="section-bag">
          <div className="wrap">
            <div className="row v-center">
              <Grid container spacing={2}>
                <Grid item md={6} display="flex" justifyContent="center" alignItems="center">
                  <div className="one-half" style={{ width: '80%' }}>
                    <img
                      src="./assets/img/bags.png"
                      width="auto"
                      height="860"
                        // @ts-ignore
                      effect="fadeInLeft"
                      alt="xpress-bagsr"
                    />
                  </div>
                </Grid>
                <Grid item md={6} display="flex" justifyContent="center" alignItems="center">
                  <div
                    className="one-half last"
                    style={{ width: '80%' }}
                  >
                    <Typography variant="h2" fontWeight="normal" textAlign="justify">
                      XPRESS Technologies
                      <strong> acelera</strong>
                      {' '}
                      la forma en
                      la que tus productos se mueven y se entregan en ciudades y
                      entre ciudades.
                    </Typography>
                    <Typography
                      variant="h3"
                      fontWeight="normal"
                      textAlign="justify"
                      mt={5}
                    >
                      Queremos acortar la distancia entre cada comercio y las
                      puertas de su clientela, por medio de una plataforma
                      pensada en todas sus necesidades.
                    </Typography>
                    <CustomizedButton
                      style={{ marginTop: '5%' }}
                      color="primary"
                      size="medium"
                      variant="contained"
                      fullWidth={false}
                      href={`${env.merchantUrl}/home`}
                      onClick={() => {}}
                    >
                      ¡Inscribe tu comercio aquí!
                    </CustomizedButton>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <Carousel
            ref={setSliderRef}
            onChange={(item) => { setActiveStep(item); }}
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            swipeable={isMobile}
            infiniteLoop
            autoPlay
            interval={10000}
            showThumbs={false}
            width={isMobile ? '100%' : '70%'}
          >
            <Box key={0}>
              <img
                style={{
                  borderTopRightRadius: isMobile ? 0 : 20,
                  borderBottomRightRadius: isMobile ? 0 : 20,
                }}
                src={data[0]?.image}
                alt={`xpress-slider${data[0]?.id}`}
              />
            </Box>
            <Box key={1}>
              <img
                style={{
                  borderTopRightRadius: isMobile ? 0 : 20,
                  borderBottomRightRadius: isMobile ? 0 : 20,
                }}
                src={data[1]?.image}
                alt={`xpress-slider${data[1]?.id}`}
              />
            </Box>
            <Box key={2}>
              <img
                style={{
                  borderTopRightRadius: isMobile ? 0 : 20,
                  borderBottomRightRadius: isMobile ? 0 : 20,
                }}
                src={data[2]?.image}
                alt={`xpress-slider${data[2]?.id}`}
              />
            </Box>
          </Carousel>
          {isMobile ? (
            <Box style={{
              width: '100%',
            }}
            >
              <Box>
                <Typography
                  m={2}
                  variant="h3"
                  fontWeight="normal"
                  textAlign="center"
                >
                  ¿Cómo
                  {' '}
                  <strong>crece</strong>
                  {' '}
                  tu comercio con Xpress?
                </Typography>
              </Box>
              <Box>
                <Typography
                  mt={3}
                  variant="h3"
                  textAlign="center"
                >
                  <strong>{data[activeStep]?.title}</strong>
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="normal"
                  textAlign="center"
                  mt={1}
                  pr={1}
                  pl={1}
                >
                  {data[activeStep]?.description}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box style={{
              width: '40%',
              position: 'absolute',
              bottom: 100,
              right: 0,
              zIndex: 1000,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              marginRight: '3%',
            }}
            >
              <Box style={{ width: '50%', marginBottom: '5%' }}>
                <Typography
                  variant="h2"
                  fontWeight="normal"
                  textAlign="start"
                >
                  ¿Cómo
                  {' '}
                  <strong>crece</strong>
                  {' '}
                  tu comercio con Xpress?
                </Typography>
              </Box>
              <ContainerArrows style={{ display: 'flex' }}>
                <ContainerArrowPrev onClick={sliderRef?.onClickPrev}>
                  <ArrowPrev color="secondary" />
                </ContainerArrowPrev>
                <ContainerArrowNext onClick={sliderRef?.onClickNext}>
                  <ArrowNext color="secondary" />
                </ContainerArrowNext>
              </ContainerArrows>
              <Box
                style={{
                  width: '80%',
                  backgroundColor: 'white',
                  padding: '6%',
                }}
              >
                <Typography
                  variant="h2"
                  textAlign="start"
                >
                  <strong>{data[activeStep]?.title}</strong>
                </Typography>
                <Typography variant="h3" fontWeight="normal" textAlign="justify" mt={2}>
                  {data[activeStep]?.description}
                </Typography>
              </Box>
            </Box>
          )}
        </div>
      </div>

      <div className="section-operations">
        <div className="wrap">
          <img
            src="./assets/img/plato-v2.png"
            className="plato"
                  // @ts-ignore
            effect="fadeInLeft"
            alt="xpress-dish1"
          />

          <div className="section-content">
            <div className="row v-center">
              <div className="three-fourths" style={isMobile ? {} : { marginBottom: '20%' }}>
                <Typography
                  pb={1}
                  variant="h3"
                  fontWeight="normal"
                  textAlign={isMobile ? 'center' : 'right'}
                >
                  XPRESS llega para darte nuevas
                  {' '}
                  <strong>herramientas </strong>
                </Typography>
                <Typography
                  pb={1}
                  variant="h3"
                  fontWeight="normal"
                  textAlign={isMobile ? 'center' : 'right'}
                >
                  <strong>indispensables para que puedas seguir </strong>
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="normal"
                  textAlign={isMobile ? 'center' : 'right'}
                >
                  <strong>vendiendo</strong>
                  {' '}
                  durante la pandemia.
                </Typography>
              </div>
            </div>

            <ul className="special-list">
              <li>
                <span>
                  <strong>Pickup: </strong>
                  Servicio único que el cliente usa para recoger su orden por
                  sí mismo.
                </span>
              </li>
              <li>
                <span>
                  <strong>Delivery: </strong>
                  Servicio de entrega a domicilio con amplio rango de
                  cobertura.
                </span>
              </li>
              <li>
                <span>
                  <strong>Pagos Online: </strong>
                  Servicio que provee mayor conveniencia y facilidad a
                  clientes.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section-information">
        <div className="wrap">
          <div className="section-content">
            <h3>
              Unirte a la
              {' '}
              <strong>familia Xpress </strong>
              solo requiere estos 3 sencillos pasos:
            </h3>
          </div>
          <div className="row">
            <div className="one-third">
              <div className="box-info box-perfeccionamos">
                <div className="box-content">
                  <h3>
                    <strong>Identificación</strong>
                  </h3>
                  <p>
                    Para registrar tu comercio de forma segura, mándanos
                    {' '}
                    <strong>
                      fotos claras de tu NIT y Cédula de identidad.
                    </strong>
                  </p>
                </div>
                <img
                  src="./assets/img/box-info/tarjeta1-icono.png"
                  alt="tarjeta1"
                  className="box-logo"
                />
              </div>
            </div>
            <div className="one-third">
              <div className="box-info box-optimizamos">
                <div className="box-content">
                  <h3>
                    <strong>Documentos</strong>
                  </h3>
                  <p>
                    Para preparar tu plataforma personalizada, envíanos
                    {' '}
                    <strong>
                      fotos claras del logo de tu comercio, un banner y el
                      contenido de menú.
                    </strong>
                  </p>
                </div>
                <img
                  src="./assets/img/box-info/tarjeta2-icono.png"
                  alt="tarjeta2"
                  className="box-logo"
                />
              </div>
            </div>
            <div className="one-third last">
              <div className="box-info box-alivianamos">
                <div className="box-content">
                  <h3>
                    <strong>Términos y condiciones</strong>
                  </h3>
                  <p>
                    Para finalizar tu inscripción, deberás leer nuestros
                    términos y condiciones y finalmente
                    {' '}
                    <strong>mandar tu confirmación firmada.</strong>
                  </p>
                </div>
                <img
                  src="./assets/img/box-info/tarjeta3-icono.png"
                  alt="tarjeta3"
                  className="box-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-locations">
        <div className="wrap">
          <div className="section-content">
            <h3>
              Puedes encontrar los
              {' '}
              <strong>servicios de XPRESS Technologies</strong>
              {' '}
              en:
            </h3>
            <div className="box-info">
              <h3>
                <strong>Santa Cruz</strong>
              </h3>
              <img
                src="./assets/img/x.png"
                width="30"
                height="auto"
                alt="xpress-x1"
              />
              <h3>
                <strong>La Paz</strong>
              </h3>
            </div>
          </div>
        </div>
        <img
          src="./assets/img/cubiertos.png"
          className="cubiertos"
          width="auto"
          height="280"
                // @ts-ignore
          effect="fadeInRight"
          alt="xpress-cubiertos"
        />
      </div>

      <div className="section-subscribe">
        <div className="section-content">
          <div className="highlight-title">
            <h3>
              <strong>
                XPRESS fue desarrollado para todos los comercios:
                {' '}
              </strong>
            </h3>
            <h3>Grandes, medianos y pequeños.</h3>
          </div>
          <div style={{ marginBottom: 60, marginTop: 60 }}>
            <Slider {...SliderRestaurantsSettings}>
              {logos.map((logo) => (
                <div className="itemRestaurantSlider" style={{ width: 50 }}>
                  <img
                    key={logo.index}
                    src={logo.src}
                    width="142"
                    height="141"
                    alt={logo.alt}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <h3>
            <strong>¡Únete a la familia hoy mismo!</strong>
          </h3>
          <CustomizedButton
            style={{ marginTop: '2%' }}
            color="primary"
            size="medium"
            variant="contained"
            fullWidth={false}
            href={`${env.merchantUrl}/home`}
            onClick={() => {}}
          >
            ¡Inscribe tu comercio aquí!
          </CustomizedButton>
          <img
            src="./assets/img/vegetales-v2.png"
            width="auto"
            height="190"
            className="vegetales"
                  // @ts-ignore
            effect="fadeInLeft"
            alt="xpress-vegetals"
          />
        </div>
      </div>
      <DownloadApps />
    </div>
  );
}

export default ContentWelcome;
