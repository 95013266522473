import { createSlice } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { Nullable } from '../../../types';
import { City } from '../../../dto';

type State = { allCities: Nullable<City[]> }

const initialState: State = { allCities: null };

export const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setAllCities: (state, action) => {
      state.allCities = action.payload;
    },
  },
});

export const { setAllCities } = citiesSlice.actions;
export default citiesSlice.reducer;

export const citiesReducerTransform = createTransform(
  (toSave:State) => ({
    ...toSave,
  }),
  (toRehydrated) => ({
    ...toRehydrated,
    allCategories:
      toRehydrated.allCities
        ? toRehydrated.allCities.map((item) => new City(item))
        : null,
  }),
  { whitelist: ['cities'] },
);
