import PriceInformationResult from './priceInformation';

export default class DeliveryInformation extends PriceInformationResult {
  hasDelivery: boolean;

  constructor({ hasDelivery = false, ...data }) {
    super(data);
    this.hasDelivery = hasDelivery;
  }
}
