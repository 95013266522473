import { Environment } from './type';

const DevEnv: Environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyBex46zHORVn63VFPsYrn4elJPhL3sxCWA',
    authDomain: 'xpress-dev.firebaseapp.com',
    databaseURL: 'https://xpress-dev.firebaseio.com',
    projectId: 'xpress-dev',
    storageBucket: 'xpress-dev.appspot.com',
    messagingSenderId: '895482157850',
    appId: '1:895482157850:web:d29538968761197d19eaa3',
    measurementId: 'G-21E38XT7JD',
  },
  cloudFunctionsUrl: 'https://us-central1-xpress-dev.cloudfunctions.net',
  xpressWebJsUrl: 'https://xpress-dev-web-js.firebaseapp.com',
  companyName: 'XPRESS Technologies',
  merchantUrl: 'https://xpress-dev-admin.web.app',
  adminUrl: 'https://xpress-dev.web.app',
  googleAnalytics: 'UA-236246807-3',
  backendUrl: 'https://xpress-api-2enzqqbxva-uc.a.run.app',
};

export default DevEnv;
