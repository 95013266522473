import storage from 'localforage';
import { userReducerTransform } from '../reducers/user';
import { addressReducerTransform } from '../reducers/address';
import { homeReducerTransform } from '../reducers/home';
import { categoriesReducerTransform } from '../reducers/category';
import { citiesReducerTransform } from '../reducers/cities';

const persistConfig = {
  key: 'root',
  storage,
  // Only add if you need persist a reducer
  whitelist: [
    'users',
    'address',
    'home',
    'categories',
    'cities',
  ],
  transforms: [
    userReducerTransform,
    addressReducerTransform,
    homeReducerTransform,
    categoriesReducerTransform,
    citiesReducerTransform,
  ],
};

export default persistConfig;
