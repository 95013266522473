import { GeoPoint } from 'firebase/firestore';
import FirebaseBase from '../firebaseBase';
import {
  Nullable,
} from '../../types';
import { toGeoPoint } from '../../utils/firestoreUtils';

export class City extends FirebaseBase {
  name: string;

  price_area_id: string;

  location: Nullable<GeoPoint>;

  coverage_polygon: GeoPoint[];

  is_default: boolean;

  name_abbreviation: string;

  constructor({
    name = '',
    price_area_id = '',
    location = null,
    coverage_polygon = [],
    is_default = false,
    name_abbreviation = '',
    ...data
  }:Partial<City>) {
    super(data);
    this.name = name;
    this.price_area_id = price_area_id;
    this.location = location ? toGeoPoint(location) : null;
    this.coverage_polygon = coverage_polygon?.map(
      (position) => toGeoPoint(position),
    );
    this.is_default = is_default;
    this.name_abbreviation = name_abbreviation;
  }
}

export default City;
